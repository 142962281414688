import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import OrgRouter from './OrgRouter';
import { PageNotFound } from './pages/ErrorPage';
import LoggedOutPage from './pages/LoggedOutPage';
import SelectOrgPage from './pages/SelectOrgPage';


function App(): React.ReactElement {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SelectOrgPage />} />
        <Route path="/loggedout" element={ <LoggedOutPage />}/>
        <Route path="/:orgId/*" element={<OrgRouter />}/>
        <Route path={'*'} element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
