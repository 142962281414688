import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import './Modal.css';


interface Props {
  children: React.ReactNode;
  id: string;
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
  title: string;
}

function Modal({ children, id, isOpen, onRequestClose, title }: Props): React.ReactElement {
  const { t } = useTranslation<string>();

  return (
    <ReactModal
      isOpen={isOpen}
      aria={
        {
          labelledby: `${id}-heading`,
          describedby: `${id}-details`,
        }
      }
      className="Modal-main"
      overlayClassName="Modal-background"
      onRequestClose={onRequestClose}
    >
      <button className="Modal-close-btn" type="button" onClick={onRequestClose} aria-label={t('Close')}/>
      <h2 id={`${id}-heading`} className="Modal-title">{title}</h2>
      <div id={`${id}-details`} className="Modal-details">
        {children}
      </div>
    </ReactModal>
  );
}

export default Modal;
