import { useEffect, useState } from 'react';

import Account from './api/Account';
import { MissingImageError, initFetchImage } from './api/Image';
import Organization from './api/Organization';


export enum FetchImageStatus {
  Loading,
  Found,
  Missing,
  Error,
}

interface FetchImageParams {
  org: Organization;
  account: Account;
}

interface FetchImageResult {
  image: string | null,
  status: FetchImageStatus,
}

export function fetchImage({org, account}:FetchImageParams): FetchImageResult {
  const [status, setStatus] = useState<FetchImageStatus>(FetchImageStatus.Loading);
  const [image, setImage] = useState<string | null>(null);
  const sessionStorageKey = `${org.id}-account-image`;

  const fetchImage = async () => {

    // Check if image is stored in localstorage
    const storedImage = window.sessionStorage.getItem(sessionStorageKey)
    if (storedImage) {
      // Validate localstorage value
      const base64regex =  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      const valid = base64regex.test(storedImage);
      if (valid) {
        setImage(storedImage);
        setStatus(FetchImageStatus.Found);
        return { image, status };
      }
    }
    
    try {
      setStatus(FetchImageStatus.Loading)
      const image = await initFetchImage(org.backendId, account.id);
      setImage(image.image);
      setStatus(FetchImageStatus.Found);
      window.sessionStorage.setItem(sessionStorageKey, image.image)
    } catch (e) {
      if (e instanceof MissingImageError) {        
        setStatus(FetchImageStatus.Missing);
      } else {
        setStatus(FetchImageStatus.Error);
      }
    }
  }

  useEffect(() => {
    fetchImage()
  }, [account])

  return { image, status };
}