import React from 'react';

import './PasswordStrengthMeter.css';


interface Props {
  strength: string;
  fill: number;
}

function PasswordStrengthMeter({ strength, fill }: Props): React.ReactElement {
  const style = {
    width: `${fill * 100}%`,
  };
  return (
    <div className="PasswordStrengthMeter">
      <div className={strength} style={style}></div>
    </div>
  );
}

export default PasswordStrengthMeter;