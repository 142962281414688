import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import PasswordHelpModal from '../components/PasswordHelpModal';
import {
  checkPassword,
  minimumPasswordStrength,
} from '../passwordpolicy';
import ErrorMessage from './ErrorMessage';
import PasswordInput from './PasswordInput';
import PasswordStrengthMeter from './PasswordStrengthMeter';

import './PasswordForm.css';


interface Props {
  account: Account;
  error?: React.ReactElement;
  oldPassword?: string;
  onChange: (password: string|undefined) => void;
}

function PasswordForm({ account, error, oldPassword, onChange }: Props): React.ReactElement {

  const { t } = useTranslation<string>();
  const org = useOrganization();
  const [rawPassword, setRawPassword] = useState('');
  const canonicalPassword = rawPassword.trim();
  const [rawRepeatedPassword, setRawRepeatedPassword] = useState('');
  const canonicalRepeatedPassword = rawRepeatedPassword.trim();
  const [showHelp, setShowHelp] = useState(false);
  const [activeError, setActiveError] = useState<boolean>(false);

  const passwordQuality = checkPassword(account, canonicalPassword, org.minPasswordLength, org.maxPasswordLength);
  let message = '';
  let strength = 'none'
  let passwordValid = false;
  if (canonicalPassword === '') {
    message = '';
    strength = 'none';
  } else if (canonicalPassword === oldPassword) {
    message = t('Cannot reuse an old password');
    strength = 'weak';
  } else if (passwordQuality.errors.length > 0) {
    message = passwordQuality.errors[0];
    strength = 'weak';
  } else if (passwordQuality.strength <= minimumPasswordStrength * 2/3) {
    message = t('Too low complexity');
    strength = 'weak';
  } else if (passwordQuality.strength < minimumPasswordStrength) {
    message = t('Too low complexity');
    strength = 'medium';
  } else if (passwordQuality.strength >= minimumPasswordStrength) {
    message = t('Good');
    strength = 'good';
    passwordValid = true;
  }

  const meter = <PasswordStrengthMeter strength={strength} fill={Math.min(1, passwordQuality.strength / minimumPasswordStrength)}/>;

  const showModal = () => {
    setShowHelp(true);
  };

  const hideModal = () => {
    setShowHelp(false);
  };

  function pwMismatch(): boolean {
    return (canonicalRepeatedPassword.length > 0 && (canonicalPassword !== canonicalRepeatedPassword));
  }

  function allOK(): boolean {
    /* Check password match and password valid */
    if(activeError) {
      return false;
    }
    return (canonicalPassword === canonicalRepeatedPassword && passwordValid);
  }

  if (allOK()) {
    onChange(canonicalPassword);
  } else {
    onChange(undefined);
  }

  useEffect(() => {
    if(error) {
      setActiveError(true);
    }
  }, [error])

  useEffect(() => {
    setActiveError(false);
  }, [canonicalPassword, canonicalRepeatedPassword])

  return (
    <>
      <p className="PasswordForm-info">{t('Feel free to use a sentence that is easy to remember, but difficult to guess.')}</p>
      <button className='PasswordForm-helpBtn' onClick={showModal}>{t('How do I create a strong password?')}</button>
      {error && <ErrorMessage showError={true} translatedMessage={error}/> }
      <div className="PasswordForm-passwords">
        <PasswordInput
          autoComplete='new-password'
          checkmark={allOK()}
          error={!passwordValid && canonicalPassword != ''}
          inputId="pw"
          message={message}
          messageOk={strength === 'good'}
          meter={meter}
          password={rawPassword}
          setPassword={setRawPassword}
        >
          {t('Enter password')}
        </PasswordInput>
        <PasswordInput
          autoComplete='new-password'
          checkmark={allOK()}
          error={pwMismatch()}
          inputId="pw-repeat"
          message={pwMismatch() ? t('The passwords do not match') : ''}
          password={rawRepeatedPassword}
          setPassword={setRawRepeatedPassword}
        >
          {t('Repeat password')}
        </PasswordInput>
      </div>
      <PasswordHelpModal show={showHelp} handleClose={hideModal} />
    </>
  );
}

export default PasswordForm;
