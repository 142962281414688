import React from 'react';
import { useTranslation } from 'react-i18next';

import checkmarkImage from './checkmark.svg';

import './Checkmark.css';


function Checkmark(): React.ReactElement {
  const { t } = useTranslation<string>();

  return (
    <div className="Checkmark">
      <img src={checkmarkImage} alt={t('Green checkmark, illustration')} />
    </div>
  );
}

export default Checkmark;
