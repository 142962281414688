import React, { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

import './VerificationCodeInputField.css'


export enum VerificationStatus {
  Idle,
  Verifying,
  Error,
  Success,
}

interface CommonProps {
  length: number;
  disabled?: boolean;
  onCompleted: (string: string) => void;
  status: VerificationStatus;
  updateStatus: (status: VerificationStatus) => void;
}

type ConditionalProps = 
| {
  inputType: 'verification';
  handleUpdate?: never;
  
}
| {
  inputType: 'PIN';
  handleUpdate?: (pin: string) => void;
}

type Props = CommonProps & ConditionalProps;


function VerificationInputField({ length, disabled, onCompleted, status, inputType, updateStatus, handleUpdate }: Props) : React.ReactElement {  

  const { t } = useTranslation<string>();
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef<(HTMLInputElement | null)[]>([])
  let classNameStatus = '';
  
  // Adding input value and focus goes to next input
  const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
    const value = e.target.value;
    
    if(inputType==='PIN' && /[^0-9]/.test(value)) return;
    
    if(inputType==='verification' && /[^A-Za-zÆæØøÅå0-9]/.test(value)) return;
    
    const newCode = [...code];
    newCode[slot] = value.toUpperCase();
    setCode(newCode);
      
    if (slot !== length -1) {
      if(newCode[slot] !== '') {
        inputs.current[slot+1]?.focus()
      }
    }

    // Check if all the inputs has value
    if (newCode.every(value => value !== '') && inputType==='verification') {
      //Then returning the code so it can be verified
      onCompleted(newCode.join(''))
    }

    if (inputType==='PIN') {
      onCompleted(newCode.join(''))
    }
  };

  // Perform onKeyDown actions
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      updateStatus(VerificationStatus.Idle)
    }
    // Removes a value and goes to previous input
    if (e.key === 'Backspace' && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot-1]?.focus()
    }
    // If PIN: allow "enter" to perform action, because we do not send PIN automatically.
    if (e.key == 'Enter' && code.join('').length === 4 && handleUpdate) {
      handleUpdate(code.join(''));
    }
  };

  switch (status) {
    case VerificationStatus.Error:
      classNameStatus = 'VerificationInputField-status-error';
      break;
    case VerificationStatus.Success:
      classNameStatus = 'VerificationInputField-status-success';
      break;
    default: classNameStatus;
  }
  
  // Add css styling based on the inputType and verification status
  const className =  `VerificationInputFieldInput ${classNameStatus}`;

  const ariaLabelText = inputType === 'verification' ? 
    t('Enter the temporary code we sent to your mobile phone number. Input 1 of 5') : 
    t('Enter a four digit pin. Input 1 of 4')

  return (
    <>
      <div className="VerificationInputField">
        {code.map((value, idx) => {
          return (
            <input className={className}
              key={idx}
              type='text'
              disabled={disabled}
              aria-disabled={disabled}
              inputMode={inputType === 'PIN' ? 'numeric' : 'text'}
              maxLength={1} 
              value={value}
              autoFocus={!code[0].length && idx === 0}
              onChange={e => processInput(e, idx)}
              onKeyDown={e => onKeyDown(e, idx)}
              ref={ref => inputs.current.push(ref)}
              aria-label={idx===0 ? ariaLabelText : t('input {idx} of {length}', { idx: `${idx+1}`, length: `${length}` })}
            />
          )
        },
        )
        }
      </div>
    </>
  )
}
export default VerificationInputField