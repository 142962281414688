import { useTranslation } from 'react-i18next';

import Account from './api/Account';

// Minimum password strength. If this field is updated, you must also update the
// `minimumPasswordStrength` field in `backend/cmd/server/passwordpolicy.go`.
export const minimumPasswordStrength = 32;

export interface PasswordQuality {
    strength: number;
    errors: string[];
}

enum CharacterClass {
    Uppercase,
    Lowercase,
    Digit,
    Special,
}
function characterClass(character: string): CharacterClass {
  if (character.toLowerCase() !== character) {
    return CharacterClass.Uppercase;
  }
  if (character.toUpperCase() !== character) {
    return CharacterClass.Lowercase;
  }
  if (!Number.isNaN(parseInt(character, 10))) {
    return CharacterClass.Digit;
  }
  return CharacterClass.Special;
}

function calcPasswordStrength(password: string): number {
  let strength = 0;
  const characterCounts = new Map<CharacterClass, Set<string>>();

  let position = 0;
  let passwordSoFar = '';
  for (const codePoint of password) {
    // Add points depending on the password length.
    let lastCodePoint = ''
    if (passwordSoFar.length > 0) {
      lastCodePoint = passwordSoFar.slice(-1);
    }
    passwordSoFar += codePoint
    const regExp = new RegExp(codePoint.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
    const count = (passwordSoFar.match(regExp) || []).length;
    if (count < 5 && codePoint != lastCodePoint) {
      position += 1; // Makes the checks for password strength vs. character position easier to read.
      if (position === 1) {
        strength += 4; // 4 poeng for det første tegnet i passordet
      } else if (position <= 1+7) {
        strength += 2; // 2 poeng for hvert av de neste syv tegn
      } else if (position <= 20) {
        strength += 1.5; // Hvert tegn fra tegn 9 til og med tegn 20 får 1.5 poeng
      } else {
        strength += 1; // Hvert tegn etter tegn 20 får 1 poeng
      }
    }
    const cCls = characterClass(codePoint);
    if (!characterCounts.has(cCls)) {
      characterCounts.set(cCls, new Set());
    }
    characterCounts.get(cCls)?.add(codePoint);
  }
  let characterClassesUsed = 0;
  let characterClassesUsedTwice = 0;
  for (const characterClassCount of Array.from(characterCounts.values())) {
    characterClassesUsed += 1;
    if (characterClassCount.size >= 2) {
      characterClassesUsedTwice += 1;
    }
  }
  if (characterClassesUsedTwice >= 4) {
    // We assume that "three" was a typo in this rule, and that the text should
    // have read "each of the four character groups" instead.
    strength += 8; // 8 poeng (total) bonus dersom passordet inneholder minst to tegn for hver av de tre tegngruppene beskrevet over
  } else if (characterClassesUsed >= 3) {
    strength += 6; // 6 poeng bonus hvis passordet inneholder tegn av minst tre av de fire tegngruppene (store bokstaver, små bokstaver, tall og spesialtegn)
  }
  return strength;
}

export function checkPassword(account: Account, password: string, minLength: number, maxLength: number): PasswordQuality {
  const { t } = useTranslation<string>();

  const errors: string[] = [];

  const passwordLength = Array.from(password).length; // Count Unicode code points instead of UTF-16 16-bit code units.
  if (passwordLength < minLength) {
    errors.push(t('Too short (minimum {{minLength}} characters)', { minLength: minLength})); // Minimum length of password is defined in backend for each org.
  } else if (passwordLength > 127 || passwordLength > maxLength) {
    errors.push(t('Too long')); // Passordet kan ikke være på over 127 tegn, fordi Active Directory ikke støtter lengre passord.
  }

  for (const codePointString of password) {
    const codePoint = codePointString.codePointAt(0) ?? 0;
    if (codePoint <= 0x1f || (codePoint >= 0x7f && codePoint <= 0xa0) || (codePoint == 0xad) || (codePoint > 0xff)) {
      errors.push(t('\'{{codePoint}}\' is not a supported character', { codePoint: codePointString })); // Passordet kan ikke inneholde tegn som ikke er en del av ISO 8859-1-kodestandarden.
      break;
    }
  }

  if (password.toLowerCase().includes(account.username.toLowerCase())) {
    errors.push(t('Cannot be similar to your username'))
  }

  for (const namePart of account.name.toLowerCase().split(' ')) {
    if (Array.from(namePart).length <= 3) {
      // Ignore parts of the name that are three characters or less, to make it
      // less likely that the name occurs in the password by random chance.
      continue;
    }
    if (password.toLowerCase().includes(namePart)) {
      errors.push(t('Cannot contain your name'))
      break;
    }
  }

  return {
    strength: calcPasswordStrength(password),
    errors: errors,
  };
}

export default checkPassword;
