import React from 'react';

import Organization from './api/Organization';


const orgContext = React.createContext<Organization|null>(null);

interface Props {
  org: Organization;
  children: React.ReactNode;
}
export function WithOrganization({ org, children }: Props): React.ReactElement {
  return (
    <orgContext.Provider value={org}>
      {children}
    </orgContext.Provider>
  );
}

export function useOptionalOrganization(): Organization|null {
  return React.useContext(orgContext);
}

export function useOrganization(): Organization {
  const org = React.useContext(orgContext);
  if (org === null) {
    throw new Error('Organization not initialized -- missing WithOrganization?');
  }
  return org;
}
