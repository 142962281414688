import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentContainer from '../components/ContentContainer';

import './ActivationStageContainer.css';


interface Props {
  btn_next?: React.ReactNode;
  btn_back?: React.ReactNode;
  children: React.ReactNode;
  currentStep: number;
  footer?: React.ReactNode;
  title: string;
}

function ActivationStageContainer({btn_next, btn_back, children, currentStep, footer, title}: Props): React.ReactElement {
  const { t } = useTranslation<string>();

  if (btn_next && footer) {
    throw new Error('Cannot specify both `button` and `footer`')
  }
  if (btn_next) {
    footer = <div className="ActivationStageContainer-button-container">
      {btn_next}
    </div>;
  }

  if (btn_next && btn_back) {
    footer = <div className="ActivationStageContainer-button-container">
      {btn_next}
      {btn_back}
    </div>;
  }

  return (
    <ContentContainer footer={footer} header={t('Activate account')} title={title} currentStep={currentStep}>
      <div className="ActivationStageContainer-content">
        {children}
      </div>
    </ContentContainer>
  )
}

export default ActivationStageContainer;
