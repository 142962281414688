import React, { useState } from 'react';

import Arrow from './dropdown.svg';

import './Dropdown.css';


interface Props {
  id: string;
  title: string;
  children: React.ReactNode;
}

function Dropdown({ id, title, children }: Props): React.ReactElement {

  const [shown, setShown] = useState(false);

  return (
    <div id={id}>
      <button
        aria-expanded={shown}
        aria-controls={`${id}-menu`}
        className="Dropdown"
        onClick={() => setShown(!shown)}>
        {title}<img src={Arrow} className="Dropdown-arrow" alt="" />
      </button>
      <ul
        id={`${id}-menu`}
        className={`Dropdown-content ${shown ? 'shown' : ''}`}>
        {children}
      </ul>
    </div>
  )

}

export default Dropdown;