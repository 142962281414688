import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
} from 'react-router-dom';

import { useOrganization } from '../OrganizationContext';
import ButtonSecondary, { SecondaryButtonType } from '../components/ButtonSecondary';
import { Contacts } from '../components/ContactAndHelpModal';
import ContentContainer from '../components/ContentContainer';
import IDPortenLoginButton from '../components/IDPortenLoginButton';
import Modal from '../components/Modal';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './LoginPage.css';


interface NoIDPortenModalProps {
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
}
function NoIDPortenModal({ isOpen, onRequestClose }: NoIDPortenModalProps) {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  const orgName = org.name.localize();
  let supportName = 'helpdesk';

  if (org.supportName) {
    supportName = org.supportName;
  }

  return <Modal
    id="no-login-idporten"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={t('Cannot log in using ID-porten')}
  >
    <p>{t('If you cannot log in using ID-porten, you will need a link to log in. This link is usually sent using email.')}</p>
    <p>{t('If you have not received a link by email or if the link is expired, you will need to contact {{supportName}} (IT-Support) at {{orgName}} to receive a new link.', { supportName, orgName })}</p>
    <Contacts />
  </Modal>
}

function LoginPage(): React.ReactElement {
  const location = useLocation();
  const org = useOrganization();
  const { t } = useTranslation<string>();
  const [showNoIDPortenModal, setShowNoIDPortenModal] = useState(false);
  
  const orgName = org.name.localize();
  const params = new URLSearchParams(location.search);
  const next = params.get('next');

  useDocumentTitle({title: 'Login'});

  let header = undefined;
  let title = t('Log in');
  let currentStep = undefined;
  let introText = t('Log in to manage your account.');
  let returnTo = `/${org.id}/`;
  let noIDPortenLinkElement = (
    <ButtonSecondary buttonText={t('Cannot use ID-porten')} onClick={(e) => {setShowNoIDPortenModal(true); e.preventDefault(); return false;}} fullWidth type={SecondaryButtonType.button} />
  );
  switch (next) {
    case 'activate':
      header = t('Activate account');
      title = t('Welcome to {{orgName}}', { orgName });
      currentStep = 1;
      introText = t('Log in using ID-porten (MinID, BankID etc.) to activate your user account and set a password.');
      returnTo = `/${org.id}/activate`;
      break;
    case 'pwchange':
      header = t('New password');
      title = t('Set new password');
      introText = t('Log in to set a new password.');
      returnTo = `/${org.id}/pwchange`;
      noIDPortenLinkElement = (
        <ButtonSecondary 
          linkTo={`/${org.id}/pwchange-without-idporten`} 
          buttonText={t('Cannot use ID-porten')} 
          type={SecondaryButtonType.link} 
          fullWidth
        />
      );
      break;
  }

  return <ContentContainer header={header} title={title} currentStep={currentStep}>
    <div className="LoginPage-intro">
      <p>{introText}</p>
    </div>
    <div className="LoginPage-idporten">
      <IDPortenLoginButton returnTo={returnTo} />
    </div>
    <div className="LoginPage-not-idporten">
      {noIDPortenLinkElement}
    </div>
    <NoIDPortenModal isOpen={showNoIDPortenModal} onRequestClose={()=>{setShowNoIDPortenModal(false)}} />
  </ContentContainer>;
}

export default LoginPage;
