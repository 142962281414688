export class PinFormatError extends Error {
  name = 'pinFormatError'
}

export class FailedToChangePinError extends Error {
  name = 'failedToChangePinError'
}

export class MissingAccesscardError extends Error {
  name = 'missingAccesscardError'
}

export async function initChangePin(orgBackendID: string, pin: string, id: string): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({
      id: id,
      pin: pin,
    }),
  }

  const res = await fetch(`/api/accounts/${orgBackendID}/change-pin`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim();
    if (res.status === 400 && responseText === 'Pin format error') {
      throw new PinFormatError();
    } else if (res.status === 400 && responseText === 'Missing accesscard') {
      throw new MissingAccesscardError();
    } else if (res.status === 500 && responseText === 'Failed to change pin') {
      throw new FailedToChangePinError();
    } else {
      throw new Error ('Error changing PIN: ' + responseText);
    }
  }
}