import React from 'react';

import { useAccount } from './account';
import Account from './api/Account';
import Organization from './api/Organization';


interface AccountContextValues {
  account: Account | null;
  error: Error | null;
  isLoading: boolean;
  redirectOnError: (e: Error, loginNext: string) => void;
  fetchAccount: () => Promise<Account|void>;
}

const accountContext = React.createContext<AccountContextValues>({} as AccountContextValues);

interface Props {
  org?: Organization;
  children: React.ReactNode;
}

export function WithAccount({ children, org }: Props): React.ReactElement {
  const { account, error, redirectOnError, isLoading, fetchAccount } = useAccount({ org })

  return (
    <accountContext.Provider value={{ account, error, isLoading, redirectOnError, fetchAccount }}>
      {children}
    </accountContext.Provider>
  );
}

export function useAccountName(): AccountContextValues {
  return React.useContext(accountContext);
}
