export class UnknownUserError extends Error {
  name = 'UnknownUserError';
}
export class UserHasNINError extends Error {
  name = 'UserHasNINError';
}

export async function initPasswordReset(orgBackendId: string, username?: string, email?: string): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      email: email,
    }),
  };

  const res = await fetch(`/api/accounts/${orgBackendId}/init-pw-reset`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim()
    if (res.status === 404 && responseText === 'Unknown user') {
      throw new UnknownUserError();
    } else if(res.status === 400 && responseText === 'User has national identity number') {
      throw new UserHasNINError();
    } else {
      throw new Error('Error initiating password reset: ' + responseText);
    }
  }
}
