import { MissingImageError } from './Image';


export class OrderingAccessCardError extends Error {
  name = 'OrderingAccessCardError';
}


interface AccessCardParams {
  orgID: string;
  accountID: string;
  pickupLocation: string;
}


export async function initOrderAccessCard({orgID, accountID, pickupLocation}:AccessCardParams): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: accountID,
      pickupLocation: pickupLocation,
    }),
  };

  const res = await fetch(`/api/accounts/${orgID}/order-access-card`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim()
    if (res.status === 400 && responseText === 'Order for access card exist') {
      throw new OrderingAccessCardError();
    } else if (res.status === 400 && responseText === 'Image is missing') {
      throw new MissingImageError();
    } else {
      throw new Error('Error ordering access card: ' + responseText);
    }
  }
}