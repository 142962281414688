import React, {
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useOrganization } from './OrganizationContext';
import {
  AccountNotReadyToActivateError,
  ExpiredInvitationCodeError,
  Invitation,
  MissingPhoneNumberError,
  UnknownInvitationCodeError,
  lookupInvitation,
} from './api/Invitation';
import LoadingSkeleton, { PageType } from './components/LoadingSkeleton';
import {
  AccountNotReadyToActivate,
  ExpiredLoginLink,
  GenericError,
  MissingPhoneNumber,
  UnknownLoginLink,
} from './pages/ErrorPage';


/**
 * Required parameter for components wrapped by withInvitation.
 */
export interface InvitationParam {
  /**
   * The invitation from the current request.
   */
  invitation: Invitation;
}

/**
 * Wrap a React component with code to fetch and check an invitation. The
 * invitation is then injected into the wrapped component.
 *
 * @param e The wrapped React component.
 * @returns A React component that fetches and checks a invitation before handing it to the wrapped component.
 */
export function withInvitation<P>(e: React.ComponentType<P & InvitationParam>): React.ComponentType<P> {
  const withInvitationImpl = (params: P) => {
    const location = useLocation();
    const org = useOrganization();
    const [invitation, setInvitation] = useState<Invitation>();
    const [error, setError] = useState<Error>();

    const urlParams = new URLSearchParams(location.search);
    const invitationId = urlParams.get('invitation') || '';

    async function loadInvitation() {
      setInvitation(undefined);
      setError(undefined);

      const lookupId = invitationId;
      let invitation = undefined;
      let error = undefined;
      try {
        invitation = await lookupInvitation(org.backendId, lookupId);
      } catch (e: unknown) {
        error = (e as Error);
      }
      if (invitationId !== lookupId) {
        // The invitationID has changed while our request was processed.
        return;
      }
      setInvitation(invitation);
      setError(error);
    }

    useEffect(() => {
      loadInvitation();
    }, [invitationId]);

    if (invitation) {
      return React.createElement(e, {'invitation': invitation, ...params})
    } else if (error instanceof ExpiredInvitationCodeError) {
      return <ExpiredLoginLink />;
    } else if (error instanceof UnknownInvitationCodeError) {
      return <UnknownLoginLink />;
    } else if (error instanceof MissingPhoneNumberError) {
      return <MissingPhoneNumber />
    } else if (error instanceof AccountNotReadyToActivateError) {
      return <AccountNotReadyToActivate />
    } else if (error) {
      return <GenericError details={ 'Error fetching accounts: ' + error} />;
    } else {
      // We have not loaded the invitation yet.
      return <LoadingSkeleton pageType={PageType.Subpage} />;
    }
  }
  return withInvitationImpl;
}
