import React, {
  SyntheticEvent,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import {
  UnknownUserError,
  UserHasNINError,
  initPasswordReset,
} from '../api/PasswordReset';
import Button from '../components/Button';
import ContentContainer from '../components/ContentContainer';
import LoadingSpinner from '../components/LoadingSpinner';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';

import './PasswordChangeWithoutIDPorten.css';


function PasswordChangeWithoutIDPorten(): React.ReactElement {
  const org = useOrganization();
  const urlHelper = useURLHelper();
  const { t } = useTranslation<string>();
  const [user, setUser] = useState('');
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useDocumentTitle({title: 'Change Password'});

  async function sendPwResetRequest(value: string) {
    value = value.trim();
    if (!value) {
      return;
    }
    let username = undefined;
    let email = undefined;
    if (value.includes('@')) {
      email = value;
    } else {
      username = value;
    }

    try {
      await initPasswordReset(org.backendId, username, email);
    } catch (e) {
      if (e instanceof UnknownUserError) {
        setError('unknown-user');
        return;
      } else if (e instanceof UserHasNINError) {
        urlHelper.redirectPush(`/${org.id}/pwchange-use-idporten`);
        return;
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
    urlHelper.redirectPush(`/${org.id}/pwchange-email-sent`);
  }

  function onSubmit(e: SyntheticEvent): boolean {
    e.preventDefault();
    setIsLoading(true);
    sendPwResetRequest(user);
    return false;
  }

  let message = '';
  if (error == 'unknown-user') {
    message = t('Unknown username or email');
  }

  const footer = (
    <div className="PasswordChangeWithoutIDPorten-footer">
      <Button onClick={onSubmit} disabled={user.trim().length === 0 || isLoading}>
        {isLoading ? <><LoadingSpinner ariaLabel={t('Sending email')} /> {t('Sending email')} </>
          : t('Send email')}
      </Button>
    </div>
  );

  return (
    <ContentContainer
      header={t('New password')}
      title={t('Set new password')}
      footer={footer}
    >
      <p className="PasswordChangeWithoutIDPorten-intro">
        {t('Enter your username or email address.')}
      </p>
      <form className="PasswordChangeWithoutIDPorten-form" onSubmit={onSubmit}>
        <label>
          {t('Username/e-mail address')}
          <input type="text" value={user} autoComplete="email" onChange={(e) => { setError(undefined); setUser(e.target.value); }} />
          <p className="PasswordChangeWithoutIDPorten-error">
            {message}
          </p>
        </label>
      </form>
      <p className="PasswordChangeWithoutIDPorten-info">
        {t('You will receive an email in a few minutes with a link to set a new password.')}
      </p>
    </ContentContainer>
  );
}

export default PasswordChangeWithoutIDPorten;
