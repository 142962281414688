import React, { MouseEventHandler } from 'react';
import { Link, Pathname,  createPath } from 'react-router-dom';

import './Button.css';


interface Props {
  linkTo?: Pathname;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  big?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode
}

function Button({linkTo, onClick, disabled = false, big = false, fullWidth = false, children} : Props): React.ReactElement {

  let classes = 'Button';
  if (disabled) classes += ' disabled';
  if (big) classes += ' big';
  if (fullWidth) classes += ' fullWidth';

  if (linkTo !== undefined) {
    const linkString = typeof linkTo === 'string' ? linkTo : createPath(linkTo);
    if (disabled) {
      return <span className={classes}>{children}</span>
    } else if(linkString.startsWith('/api/')) {
      // The <Link> element can only be used for internal links, so links to
      // things that are not in the React App must be a normal link element.
      return <a href={linkString} className={classes}>{children}</a>
    } else {
      return <Link to={linkTo} className={classes}>{children}</Link>
    }
  } else {
    return <button disabled={disabled} onClick={onClick} className={classes}>{children}</button>
  }

}

export default Button;