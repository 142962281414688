import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate  } from 'react-router-dom';

import { useOptionalOrganization, useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import Button from '../components/Button';
import { Contacts } from '../components/ContactAndHelpModal';
import ContentContainer from '../components/ContentContainer';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';
import ErrorImage1 from './errorimage1.png'

import './ErrorPage.css';


export interface ErrorState {
  details?: string,
  invitationId?: string
}

interface Props {
  button?: React.ReactNode,
  title?: string,
  showIcon?: boolean,
  children: React.ReactNode
}

function ErrorContainer({button, title = '', showIcon = true, children}: Props): React.ReactElement {

  useDocumentTitle({title: 'Error'})
  const icon = showIcon ? <img className='ErrorPage-error-image' src={ErrorImage1} alt='' /> : <></>;
  let buttonBar;
  if (button) {
    buttonBar = <div className="ErrorPage-button-container">
      {button}
    </div>;
  }
  return (
    <ContentContainer
      className="ErrorPage"
      footer={buttonBar}
      title={<>
        {title}
        {icon}
      </>}
    >
      {children}
    </ContentContainer>
  );
}

interface AccountAlreadyActivatedParams {
  account: Account;
}
export function AccountAlreadyActivated({ account }: AccountAlreadyActivatedParams): React.ReactElement {
  const urlHelper = useURLHelper();
  const { t } = useTranslation<string>();
  const username = account.username;
  return (
    <ErrorContainer
      title={t('Account already activated')}
    >
      <p>{t('Your user account is already activated.')}</p>
      <p><Trans>Your username is: <code>{{username}}</code></Trans></p>
      <p><Trans>If you have forgotten your password, you can <Link to={urlHelper.passwordChange()}>change your password</Link>.</Trans></p>
      <Contacts />
    </ErrorContainer>
  );
}

export function AccountNotActivated(): React.ReactElement {
  const urlHelper = useURLHelper();
  const { t } = useTranslation<string>();
  return (
    <ErrorContainer
      title={t('Account not activated')}
    >
      <p>{t('Your user account is not activated.')}</p>
      <p><Trans> <Link to={urlHelper.activate()}>Activate your account</Link> to access account changes.</Trans></p>
      <Contacts />
    </ErrorContainer>
  );
}

function NoUserAccountToActivate(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOptionalOrganization();

  let logoutPage = '/loggedout';
  let orgName = 'Universitetet'
  if (org) {
    logoutPage = `/${org.id}/loggedout`;
    orgName = org.name.localize()
  }
  const logoutLink = '/api/logout?' + (new URLSearchParams({'return': logoutPage})).toString()

  return (
    <ErrorContainer
      button={<Button onClick={() => window.location.href=`${logoutLink}`}>{t('Logout')}</Button>}
      title={t('No user account')}
    >
      <p><Trans>You got logged in, but we found no account with your user information at {{orgName}}. Contact helpdesk in case there is an error.</Trans></p>
      <Contacts />
    </ErrorContainer>
  );
}

export function AccountNotReadyToActivate(): React.ReactElement {
  // This error will be shown to both users logged into ID-Porten and users using invitation link
  // If url contains invitation we will not display logout button.
  const { t } = useTranslation<string>();
  const org = useOptionalOrganization();
  const urlParams = new URLSearchParams(location.search);
  const invitation = urlParams.get('invitation');

  let logoutPage = '/loggedout';
  if (org) {
    logoutPage = `/${org.id}/loggedout`;
  }
  const logoutLink = '/api/logout?' + (new URLSearchParams({'return': logoutPage})).toString()

  return (
    <ErrorContainer 
      title={t('Account not ready')} 
      button={!invitation && <Button linkTo={logoutLink}>{t('Logout')}</Button>}
    >
      <p>{t('Please try again later, or contact helpdesk if the problem persists.')}</p>
      <Contacts />
    </ErrorContainer>
  )
}

export function ExpiredLoginLink(): React.ReactElement {
  const { t } = useTranslation<string>();
  return (
    <ErrorContainer title={t('Login link has expired')}>
      <p>{t('Contact the helpdesk at your organization if you need a new login link.')}</p>
      <Contacts />
    </ErrorContainer>
  );
}

export function ErrorLoadingData(): React.ReactElement {
  const { t } = useTranslation<string>();
  return (
    <ErrorContainer title={t('Error loading data')}>
      <p>{t('An error occurred while loading your account. Please try again, or contact helpdesk if the error persists.')}</p>
      <Contacts />
    </ErrorContainer>
  )
}

export function UnknownLoginLink(): React.ReactElement {
  const { t } = useTranslation<string>();
  return (
    <ErrorContainer title={t('Unknown login link')}>
      <p>{t('The login link is incorrect.')}</p>
      <p>{t('Check that you have entered the login link correctly in your browser.')}</p>
      <p>{t('Contact the helpdesk at your organization if you need a new login link.')}</p>
      <Contacts />
    </ErrorContainer>
  );
}

export function MissingPhoneNumber(): React.ReactElement {
  const { t } = useTranslation<string>();
  return (
    <ErrorContainer title={t('Missing phone number')}>
      <p>{t('Your account has no phone number registered.')}</p>
      <p>{t('Contact the helpdesk at your organization to register a phone number to your account.')}</p>
      <Contacts />
    </ErrorContainer>
  )
}

interface GenericErrorProps {
  details?: string,
}
export function GenericError({details}: GenericErrorProps): React.ReactElement {
  const { t } = useTranslation<string>();
  let detailsContent = <></>;
  if (details) {
    detailsContent = <p className="ErrorPage-details">
      {details}
    </p>;
  }

  return (
    <ErrorContainer title={t('An error has occurred')}>
      <p>{t('We apologize. Try again later or contact the helpdesk')}:</p>
      {detailsContent}
      <Contacts />
    </ErrorContainer>
  );
}

interface ErrorVerifyingSmsCodeProps {
  details?: string,
  invitationId?: string
}
export function ErrorVerifyingSmsCode({details, invitationId}: ErrorVerifyingSmsCodeProps): React.ReactElement {
  const { t } = useTranslation<string>();
  const history = useNavigate();
  const org = useOrganization();
  let params: URLSearchParams;
  let detailsContent = <></>;
  if (invitationId) {
    params = new URLSearchParams({'invitation': invitationId})
  }
  if (details) {
    detailsContent = <p className="ErrorPage-details">
      {details}
    </p>
  }
  return (
    <ErrorContainer button={<Button onClick={() => history(`/${org.id}/invitation?${params}`)}>{t('Try again')}</Button>} title={t('An error has occurred')}>
      <p>{t('We apologize. An error occurred while verifying your SMS code.')}</p>
      {detailsContent}
      <p>{t('Please try again or contact helpdesk:')}</p>
      <Contacts />
    </ErrorContainer>
  );
}

export function PageNotFound(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOptionalOrganization();
  const redirectLink = org ? '/'+org.id : '/'

  return (
    <ErrorContainer
      button={
        <Link to={`${redirectLink}`}>
          <Button>{t('Go to frontpage')}</Button>
        </Link>
      }
      title={t('Unable to locate page') + ' (404)'}
    >
      <p>{t('We\'re sorry. The page might have moved, changed its name or the page is temporarily unavailable')}.</p>
    </ErrorContainer>
  );
}


export function ErrorPage(): React.ReactElement {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const error = urlParams.get('error') || '';
  const state = location.state as ErrorState;

  switch (error.toLowerCase()) {
    case 'nouseraccounttoactivate': {
      return <NoUserAccountToActivate />
    }
    case 'accountnotreadytoactivate': {
      return <AccountNotReadyToActivate />
    }
    case 'errorverifyingsmscode': {
      return <ErrorVerifyingSmsCode details={state?.details} invitationId={state?.invitationId} />
    }
    default: {
      return <GenericError details={state?.details} />
    }
  }
}
