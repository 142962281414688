import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOptionalOrganization } from '../OrganizationContext';
import BottomBar from './BottomBar';
import TopBar from './TopBar';

import './BasePage.css';


interface Props {
  children: React.ReactNode;
}

function BasePage({ children }: Props): React.ReactElement {
  let className = 'BasePage';
  const org = useOptionalOrganization();
  const { t } = useTranslation();
  if (org) {
    className += ` org-${org.id}`
  }

  return (
    <div className={className}>
      <a className='BasePage-skip-link' href='#main'>{t('Skip to content')}</a>
      <header>
        <TopBar />
      </header>
      <main id='main'>
        {children}
      </main>
      <BottomBar />
    </div>
  );
}

export default BasePage;
