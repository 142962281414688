import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Organization,
  getOrganizations,
} from '../api/Organization';
import ContentContainer from '../components/ContentContainer';


function SelectOrgPage(): React.ReactElement  {
  const [organizations, setOrganizations] = useState<Array<Organization>>([]);

  async function initOrg() {
    setOrganizations(await getOrganizations());
  }
  useEffect(() => { initOrg(); }, []);

  const organizationsList = organizations.map((org) => {
    return (
      <li key={org.id}>
        <Link to={`/${org.id}/`}>{org.name.localize()}</Link>
      </li>
    );
  });

  return (
    <ContentContainer>
      <ul>
        {organizationsList}
      </ul>
    </ContentContainer>
  )
}

export default SelectOrgPage;
