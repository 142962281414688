import React from 'react';
import { Link, Pathname, createPath } from 'react-router-dom';

import './ButtonSecondary.css';


export enum SecondaryButtonType {
  link = 'link',
  externalLink = 'externalLink',
  button = 'button',
}

export enum SecondaryButtonVariant {
  outlinedGhost = 'OutlinedGhost',
  ghost = 'Ghost',
  contained = 'Contained',
}

interface SecondaryButtonProps {
  buttonText?: string;
  linkTo?: Pathname | string;
  onClick?:(e: React.MouseEvent | React.KeyboardEvent) => void;
  ariaLabel?: string;
  type: SecondaryButtonType;
  variant?: SecondaryButtonVariant;
  fullWidth?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

interface ButtonRouterLinkProps extends SecondaryButtonProps{
  linkTo: Pathname;
  type: SecondaryButtonType.externalLink;
  onClick?: never; 
}

interface ButtonLinkProps extends SecondaryButtonProps {
  linkTo: string;
  onClick?: never;
  type: SecondaryButtonType.link;
}

interface ButtonClickProps extends SecondaryButtonProps{
  linkTo?: never;
  onClick:(e: React.MouseEvent | React.KeyboardEvent) => void;
  type: SecondaryButtonType.button;
}

type ButtonSecondaryProps = ButtonClickProps | ButtonLinkProps | ButtonRouterLinkProps;

export default function ButtonSecondary({
  type = SecondaryButtonType.button, 
  buttonText: linkText, 
  linkTo, 
  onClick, 
  ariaLabel, 
  variant = SecondaryButtonVariant.ghost, 
  fullWidth, 
  children, 
  disabled,
}: ButtonSecondaryProps): React.ReactElement {
  
  const classNames = `ButtonSecondary ${variant ? variant : ''} ${fullWidth ? 'FullWidth' : ''} ${disabled ? 'Disabled' : ''}`;

  const DisabledLink = () => <a tabIndex={0} className={classNames} role='link' aria-disabled='true'>{[linkText, children]}</a>;

  if(type === SecondaryButtonType.link && typeof linkTo === 'string'){
    if(disabled) return <DisabledLink/>

    else{
      return <Link to={linkTo} className={classNames} aria-label={ariaLabel}>{[linkText, children]}</Link>
    }
  }

  if(type === SecondaryButtonType.externalLink && linkTo && typeof linkTo !== 'string' ){
    if(disabled) return <DisabledLink/>
    else{
      return <Link to={createPath(linkTo)} className={classNames} aria-label={ariaLabel}>{[linkText, children]}</Link>
    }
  } 
  
  return <button aria-disabled={disabled} disabled={disabled} onClick={onClick} className={classNames} aria-label={ariaLabel}>{[linkText, children]}</button>
}
