import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import { SimplifiedLink, SimplifiedLinkText } from './SimplifiedLink';


function OrganizationWebsiteLink(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  return (
    <SimplifiedLink href={org.website.localize()}>
      {t('To')} <SimplifiedLinkText/>
    </SimplifiedLink>
  );
}

export default OrganizationWebsiteLink;
