import React from 'react';

import './ContentProgress.css';


interface Props {
  currentStep?: number;
  steps?: number;
}

function ContentProgress({ currentStep = 0, steps = 8 }: Props): React.ReactElement {

  function renderBar(): JSX.Element[] {
    const comps: JSX.Element[] = [];

    for (let i = 0; i < steps; i++) {
      comps.push(<div key={`progressbar-${i}`} className={`ContentProgress-bar-item ${i < currentStep ? 'active' : ''}`}></div>);
    }

    return comps;
  }

  return <div className="ContentProgress-bar">{renderBar()}</div>;

}

export default ContentProgress;