import i18next from 'i18next';


class LocalizedString {
  values: Map<string, string>;

  constructor(values: Map<string, string>) {
    this.values = values;
  }

  localize(): string {
    for (const language of i18next.languages) {
      const value = this.values.get(language);
      if (value !== undefined) {
        return value;
      }
    }
    // Fallback -- just pick any string.
    for (const value of Array.from(this.values.values())) {
      return value;
    }

    // We should never initialize an empty LocalizedString.
    throw new Error('Empty LocalizedString');
  }
}

type ProfilePageOptionType = {
  [key: string]: string;
}
const options:ProfilePageOptionType = {
  UploadImage: 'uploadImage',
  RemoveImage: 'removeImage',
  Pincode: 'changePincode',
  AccessCard: 'orderAccessCard',
} as const
// ProfilePageOptions are what options to be displayed for the user in ProfilePage.
// These options are org specific and defined in the backend.
class ProfilePageOptions {
  values: Map<string, boolean>;

  constructor(values: Map<string, boolean>) {
    this.values = values;
  }

  uploadImage(): boolean {
    const value = this.values.get(options.UploadImage)
    if (value !== undefined) {
      return value;
    }
    throw new Error('Empty uploadImage value')
  }

  removeImage(): boolean {
    const value = this.values.get(options.RemoveImage)
    if (value !== undefined) {
      return value;
    }
    throw new Error('Empty removeImage value')
  }

  changePincode(): boolean {
    const value = this.values.get(options.Pincode)
    if (value !== undefined) {
      return value;
    }
    throw new Error('Empty changePincode value')
  }

  orderAccessCard(): boolean {
    const value = this.values.get(options.AccessCard)
    if (value !== undefined) {
      return value
    }
    throw new Error('Empty orderAccessCard value')
  }
}

export class Organization {
  constructor(
    readonly id: string,
    readonly backendId: string,
    readonly minPasswordLength: number,
    readonly maxPasswordLength: number,
    readonly name: LocalizedString,
    readonly website: LocalizedString,
    readonly favicon: string,
    readonly topbarLogo: LocalizedString,
    readonly formattedName: string,
    readonly ictRegulations: LocalizedString,
    readonly itDepLink: LocalizedString,
    readonly privacyStatement: LocalizedString,
    readonly profilePageOptions: ProfilePageOptions,
    readonly supportName?: string,
    readonly supportLink?: LocalizedString,
    readonly supportLinkStudent?: LocalizedString,
    readonly supportPhone?: string,
    readonly supportChat?: LocalizedString,
    readonly supportEmail?: string,
    readonly postActivationLink?: LocalizedString,
    readonly postActivationMessage?: LocalizedString,
    readonly postActivationMessageLink?: LocalizedString,
    readonly imageRequirementsLink?: LocalizedString,
    readonly imageUsageLink?: LocalizedString,
    readonly accessCardRulesLink?: LocalizedString,
    readonly accessCardPickupLocations?: Array<string>,
    readonly accessCardMessage?: LocalizedString,
    readonly localPasswordPolicyLink?: LocalizedString,
  ) {
  }
}

type ApiLocalizedString = {
  [key: string]: string;
}
type ApiProfilePageOptions = {
  [key: string]: boolean;
}
interface ApiOrganization {
  id: string;
  minPasswordLength: number,
  maxPasswordLength: number,
  name: ApiLocalizedString;
  website: ApiLocalizedString;
  favicon: string;
  topbarLogo: ApiLocalizedString;
  formattedName: string;
  ictRegulations: ApiLocalizedString;
  itDepLink: ApiLocalizedString;
  privacyStatement: ApiLocalizedString;
  shortname: string;
  profilePageOptions: ApiProfilePageOptions;
  supportName?: string;
  supportLink?: ApiLocalizedString;
  supportLinkStudent?: ApiLocalizedString;
  supportPhone?: string;
  supportChat?: ApiLocalizedString;
  supportEmail?: string;
  postActivationLink?: ApiLocalizedString;
  postActivationMessage?: ApiLocalizedString;
  postActivationMessageLink?: ApiLocalizedString;
  imageRequirementsLink?: ApiLocalizedString;
  imageUsageLink?: ApiLocalizedString;
  accessCardRulesLink?: ApiLocalizedString;
  accessCardPickupLocations?: Array<string>;
  accessCardMessage?: ApiLocalizedString;
  localPasswordPolicyLink?: ApiLocalizedString;
}

export class OrganizationNotFoundError extends Error {
  name = 'OrganizationNotFoundError';
}

function parseOptionalLocalizedString(value: ApiLocalizedString|undefined): LocalizedString|undefined {
  if (!value) {
    return undefined;
  }
  const map = new Map(Object.entries(value));
  if (map.size === 0) {
    return undefined;
  }
  return new LocalizedString(map);
}

function parseMandatoryLocalizedString(value: ApiLocalizedString): LocalizedString {
  const ret = parseOptionalLocalizedString(value);
  if (!ret) {
    throw new Error('Empty LocalizedString value');
  }
  return ret;
}

function parseProfilePageOptions(value: ApiProfilePageOptions): ProfilePageOptions {
  if (!value) {
    throw new Error('Empty ProfilePageOptions value');
  }
  const map = new Map(Object.entries(value))
  if (map.size === 0) {
    throw new Error('Empty ProfilePageOptions value')
  }
  return new ProfilePageOptions(map);
}

export async function getOrganizations(): Promise<Array<Organization>> {
  const res = await fetch('/api/organizations/');
  if (!res.ok) {
    throw new Error('Error fetching organizations');
  }
  const organizations = await res.json();
  return organizations.map((orgConfig: ApiOrganization) => new Organization(
    orgConfig.shortname,
    orgConfig.id,
    orgConfig.minPasswordLength,
    orgConfig.maxPasswordLength,
    parseMandatoryLocalizedString(orgConfig.name),
    parseMandatoryLocalizedString(orgConfig.website),
    orgConfig.favicon,
    parseMandatoryLocalizedString(orgConfig.topbarLogo),
    orgConfig.formattedName,
    parseMandatoryLocalizedString(orgConfig.ictRegulations),
    parseMandatoryLocalizedString(orgConfig.itDepLink),
    parseMandatoryLocalizedString(orgConfig.privacyStatement),
    parseProfilePageOptions(orgConfig.profilePageOptions),
    orgConfig.supportName,
    parseOptionalLocalizedString(orgConfig.supportLink),
    parseOptionalLocalizedString(orgConfig.supportLinkStudent),
    orgConfig.supportPhone,
    parseOptionalLocalizedString(orgConfig.supportChat),
    orgConfig.supportEmail,
    parseOptionalLocalizedString(orgConfig.postActivationLink),
    parseOptionalLocalizedString(orgConfig.postActivationMessage),
    parseOptionalLocalizedString(orgConfig.postActivationMessageLink),
    parseOptionalLocalizedString(orgConfig.imageRequirementsLink),
    parseOptionalLocalizedString(orgConfig.imageUsageLink),
    parseOptionalLocalizedString(orgConfig.accessCardRulesLink),
    orgConfig.accessCardPickupLocations,
    parseOptionalLocalizedString(orgConfig.accessCardMessage),
    parseOptionalLocalizedString(orgConfig.localPasswordPolicyLink),
  ));
}

export async function getOrganization(orgId: string): Promise<Organization> {
  const organizations = await getOrganizations();
  for (const organization of organizations) {
    if (organization.id == orgId) {
      return organization;
    }
  }
  throw new OrganizationNotFoundError();
}

interface MakeTestOrganizationParams {
  id?: string;
  backendId?: string;
  minPasswordLength?: number;
  maxPasswordLength?: number;
  name?: string;
  website?: string;
  favicon?: string;
  topbarLogo?: string;
  formattedName?: string;
  ictRegulations?: string;
  itDepLink?: string;
  privacyStatement?: string;
  profilePageOptions?: boolean;
  supportName?: string;
  supportLink?: string;
  supportLinkStudent?: string;
  supportPhone?: string;
  supportChat?: string;
  supportEmail?: string;
  postActivationLink?: string;
  postActivationMessage?: string;
  postActivationMessageLink?: string;
  imageRequirementsLink?: string;
  imageUsageLink?: string;
  accessCardRulesLink?: string;
  accessCardPickupLocations?: Array<string>;
  accessCardMessage?: string;
  localPasswordPolicyLink?: string;
}
export function makeTestOrganization(params: MakeTestOrganizationParams = {}): Organization {
  const locStr = (str: string): LocalizedString => {
    return parseMandatoryLocalizedString({
      '-': str,
    })
  }
  const profileOpt = (value: boolean): ProfilePageOptions => {
    return parseProfilePageOptions({
      '-': value,
    })
  }
  return new Organization(
    params.id ?? 'test',
    params.backendId ?? 'TestBackend',
    params.minPasswordLength ?? 12,
    params.maxPasswordLength ?? 127,
    locStr(params.name ?? 'Testorganization'),
    locStr(params.website ?? 'https://example.org/'),
    params.favicon ?? 'test.svg',
    locStr(params.topbarLogo ?? 'testLogo.svg'),
    params.formattedName ?? 'Test',
    locStr(params.ictRegulations ?? 'https://example.org/ict-regulations'),
    locStr(params.itDepLink ?? 'https://example.org/it-department'),
    locStr(params.privacyStatement ?? 'https://example.org/privacy-statement'),
    profileOpt(params.profilePageOptions ?? false),
    params.supportName ?? 'itsupport',
    locStr(params.supportLink ?? 'https://example.org/support'),
    locStr(params.supportLinkStudent ?? 'https://example.org/support/student'),
    params.supportPhone ?? '(+28) 64 83 76 23',
    locStr(params.supportChat ?? 'https://example.org/chat'),
    params.supportEmail ?? 'support@example.org',
    locStr(params.postActivationLink ?? 'https://example.org/welcome'),
    locStr(params.postActivationMessage ?? 'Example post activation message.'),
    locStr(params.postActivationMessageLink ?? 'PostMsgLink.org'),
    locStr(params.imageRequirementsLink ?? 'https://example.org/image-requirements'),
    locStr(params.imageUsageLink ?? 'https://example.org/where-is-the-image-used'),
    locStr(params.accessCardRulesLink ?? 'https://example.com/accesscard-rules'),
    params.accessCardPickupLocations ?? ['TestCampus1', 'TestCampus2'],
    locStr(params.accessCardMessage ?? 'Example accesscard message.'),
    locStr(params.localPasswordPolicyLink ?? 'https://example.org/password-policy'),
  )
}

export default Organization;
