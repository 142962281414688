import React, { useEffect } from 'react';

import { useAccountName } from './AccountContext';
import LoadingSkeleton, { PageType } from './components/LoadingSkeleton';
import ActivateAccountPage from './pages/ActivateAccountPage';
import { AccountAlreadyActivated, ErrorLoadingData } from './pages/ErrorPage';


function Activate(): React.ReactElement {
  const {account, error, isLoading, redirectOnError} = useAccountName();
  
  useEffect(() => {
    if(error){
      redirectOnError(error, 'activate'); 
    }
  }, [error])

  if(!account && !error && !isLoading) {
    return <ErrorLoadingData />
  }

  // Account not loaded yet, displaying a loading skeleton
  if (!account) {
    return <LoadingSkeleton pageType={PageType.Subpage} />
  }
  
  if (account.activated) {
    return <AccountAlreadyActivated account={account} />
  }
  return (
    <ActivateAccountPage account={account} />
  );
}

export default Activate;
