import React, { useState } from 'react';

import ActivatedStage from '../activation-stages/ActivatedStage';
import ConsentInfoStage from '../activation-stages/ConsentInfoStage';
import ConsentStage from '../activation-stages/ConsentStage';
import PasswordStage from '../activation-stages/PasswordStage';
import Account from '../api/Account';


interface Props {
  account: Account;
}

function ActivateAccountPage({ account }: Props): React.ReactElement {

  enum Stage {
    ConsentInfo,
    Consent,
    Password,
    Completed,
  }

  const [stage, setStage] = useState<Stage>(Stage.ConsentInfo);

  const [currentStep, setCurrentStep] = useState(1);

  function returnToConsentInfo() {
    setCurrentStep(5);
    setStage(Stage.ConsentInfo)    
  }

  switch (stage) {
    case Stage.ConsentInfo:
      return <ConsentInfoStage onCompleted={() => setStage(Stage.Consent)} getCurrentStep={currentStep} />;
    case Stage.Consent:
      return <ConsentStage onCompleted={() => setStage(Stage.Password)} onBack={() => returnToConsentInfo()} />;
    case Stage.Password:
      return <PasswordStage account={account} onCompleted={() => setStage(Stage.Completed)} onBack={() => setStage(Stage.Consent)} />;
    case Stage.Completed:
      return <ActivatedStage account={account}/>;
  }
}

export default ActivateAccountPage;
