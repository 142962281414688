import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account'; 
import { FailedToChangePinError, MissingAccesscardError, PinFormatError, initChangePin } from '../api/ChangePin';
import ButtonSecondary, { SecondaryButtonType, SecondaryButtonVariant } from '../components/ButtonSecondary';
import Checkmark from '../components/Checkmark';
import ContentContainer from '../components/ContentContainer';
import ErrorMessage from '../components/ErrorMessage';
import LoadingSpinner from '../components/LoadingSpinner';
import VerificationInputField, { VerificationStatus } from '../components/VerificationCodeInputField';
import './ChangePinPage.css';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';


 interface Props {
   account: Account;
 }

function ChangePinPage({account}: Props): React.ReactElement {
  const { t } = useTranslation();
  const [pinStatus, setPinStatus] = useState<VerificationStatus>(VerificationStatus.Idle);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [pin, setPin] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const org = useOrganization();
  const orgName = org.name.localize();
  const urlHelper = useURLHelper();

  useDocumentTitle({title: 'Change PIN'});

  async function changePin(pin: string) {
    if (pin.length !== 4 || /[^0-9]/.test(pin)) {
      setErrorMessage('The PIN must have exactly 4 digits');
      setPinStatus(VerificationStatus.Error);
      setShowError(true);
      return <></>
    }
    try {
      setShowError(false);
      setIsDisabled(true);
      setPinStatus(VerificationStatus.Verifying);
      await initChangePin(
        org.backendId,
        pin,
        account.id,
      );
      setPinStatus(VerificationStatus.Success);
    } catch (e) {
      setPinStatus(VerificationStatus.Error);
      setIsDisabled(false)
      if (e instanceof PinFormatError) {
        setErrorMessage('Entered PIN is incorrectly formatted. PIN must be 4 digits. Contact helpdesk if you experience problems');
      } else if (e instanceof MissingAccesscardError) {
        setErrorMessage('No access card is registered on your account, therefore we cannot change your PIN. Contact helpdesk if you experience problems.');
      } else if (e instanceof FailedToChangePinError) {
        setErrorMessage('Error changing PIN in our systems. Please try again, or contact helpdesk if the problem persists');
      } else {
        // Unknown error
        urlHelper.genericError((e as Error).message);
        return
      }
      setShowError(true);
    }
  }
  

  const savePinButtonText = pinStatus === VerificationStatus.Verifying
    ? <><LoadingSpinner ariaLabel='Saving PIN' />{t('Saving PIN')} </>
    : pinStatus === VerificationStatus.Success
      ? t('PIN saved')
      : t('Save PIN');

  return (
    <ContentContainer
      header={t('Change PIN')}
      title={t('Change PIN')}
      footer={
        <div className='ChangePinPage-footer'>
          <ButtonSecondary
            type={SecondaryButtonType.link}
            variant={SecondaryButtonVariant.contained}
            linkTo={`/${org.id}/profile`}
            buttonText={t('Back')}>
          </ButtonSecondary>
          {
            pin.length === 4 &&
          <ButtonSecondary
            disabled={isDisabled}
            type={SecondaryButtonType.button}
            variant={SecondaryButtonVariant.contained}
            onClick={(e) => { e.preventDefault(); changePin(pin); }}
          >{savePinButtonText}
          </ButtonSecondary>
          }
        </div>
      }>
      <p><Trans>The PIN is connected to your access card at {{ orgName }}. Note that it might take a couple of hours for the new PIN to work with your access card.</Trans></p>
      <label><Trans>Enter a four digit PIN:</Trans>
        <VerificationInputField
          length={4}
          inputType='PIN'
          onCompleted={(pin) => {setPin(pin); setShowError(false);}}
          handleUpdate={changePin}
          disabled={isDisabled}
          updateStatus={(status) => setPinStatus(status)}
          status={pinStatus}
        />
      </label>

      <ErrorMessage showError={showError} message={errorMessage} />

      { pinStatus === VerificationStatus.Success &&
        <div aria-live='polite' role='status' className='ChangePinPage-status-success'>
          <Checkmark />
          <p><Trans>PIN has been updated in our systems</Trans></p>
        </div>
      }
    </ContentContainer>
  )
}

export default ChangePinPage;