import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import {
  ConfigAccountUserError,
  ContextBasedPasswordError,
  PasswordCriteriaNotMetError,
  PasswordFoundInBreachError,
  PasswordHasBeenUsedError,
  configAccount,
} from '../api/ConfigAccount';
import ActivationStageContainer from '../components/ActivationStageContainer';
import Button from '../components/Button';
import LoadingSpinner from '../components/LoadingSpinner';
import PasswordForm from '../components/PasswordForm';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';


interface Props {
  account: Account;
  onCompleted: () => void;
  onBack: () => void;
}

function PasswordStage({ account, onCompleted, onBack }: Props): React.ReactElement {

  const urlHelper = useURLHelper();
  const { t } = useTranslation<string>();
  const [activationError, setActivationError] = useState<React.ReactElement>();
  const [oldPassword, setOldPassword] = useState<string|undefined>();
  const [password, setPassword] = useState<string|undefined>();
  const org = useOrganization();
  const orgname = org.name.localize();
  const passwordPolicyLink = org.localPasswordPolicyLink ? org.localPasswordPolicyLink.localize() : org.supportLink?.localize();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useDocumentTitle({title: 'Set password'});

  const activateUser = async () => {
    setIsLoading(true);
    if (password === undefined) {
      throw new Error('Tried to activate user without valid password');
    }
    try {
      await configAccount({
        activate: true,
        password: password,
        orgID: org.backendId,
        accountID: account.id,
      });
    } catch(e: unknown) {
      if (e instanceof PasswordFoundInBreachError) {        
        setActivationError(<Trans>Desired password was found in a breach. Read more at: <a target="_blank" rel="noopener noreferrer" href='https://haveibeenpwned.com'>haveibeenpwned.com</a></Trans>);
      } else if (e instanceof ContextBasedPasswordError) {
        setActivationError(<Trans>Password cannot contain information related to {{orgname}}.</Trans>)
      } else if (e instanceof PasswordHasBeenUsedError) {
        setOldPassword(password);
      } else if (e instanceof PasswordCriteriaNotMetError) {
        setActivationError(<Trans>Password does not meet the required criteria. Read more at: <a target="_blank" rel="noopener noreferrer" href={passwordPolicyLink}>{{passwordPolicyLink}}</a></Trans>)
      } else if (e instanceof ConfigAccountUserError) {
        setActivationError(<Trans>{(e as Error).message}</Trans>)
      } else {
        const msg = 'Failed to set password for user: ' + (e as Error).message;
        urlHelper.genericError(msg);
      }
      return;
    } finally {
      setIsLoading(false);
    }
    onCompleted();
  };

  const button = <Button onClick={activateUser} disabled={password===undefined || isLoading}>
    {isLoading ? 
      <>
        <LoadingSpinner ariaLabel={t('Saving password')} />
        {t('Saving')}
      </>
      : t('Save password')}
  </Button>;

  const btn_back = <Button onClick={onBack}>{t('Back')}</Button>

  return (
    <>
      <ActivationStageContainer title={t('Set password')} currentStep={7} btn_next={button} btn_back={btn_back}>
        <PasswordForm
          account={account}
          error={activationError}
          oldPassword={oldPassword}
          onChange={setPassword}
        />
      </ActivationStageContainer>
    </>
  );
}

export default PasswordStage;
