import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOptionalOrganization } from '../OrganizationContext';
import Checkmark from '../components/Checkmark';
import ContentContainer from '../components/ContentContainer';
import OrganizationWebsiteLink from '../components/OrganizationWebsiteLink';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './LoggedOutPage.css';


function LoggedOutPage(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOptionalOrganization();

  useDocumentTitle({title: 'Logged out'});

  let orgLink = <></>;
  if (org) {
    orgLink = <OrganizationWebsiteLink />
  }

  return (
    <ContentContainer header={t('Logout account')} title={t('Logout completed')}>
      <div className="LoggedOutPage">
        <p>
          {t('You have been successfully logged out.')}
          <br /><br />
          {t('You can close this window.')}
        </p>
        <Checkmark />
        <div className="LoggedOutPage-bottom-container">
          {orgLink}
        </div>
      </div>
    </ContentContainer>
  )
}

export default LoggedOutPage;
