import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './translations/en.json';
import translationNorwegianBokmal from './translations/nb.json';


i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      'en': {translation: translationEnglish},
      'nb': {translation: translationNorwegianBokmal},
    },
    fallbackLng: {
      'nb': ['en'],
      'en': ['nb'],
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // Key separator allows us to use hierarchical keys. The default separator
    // is '.'. Since we are using translation strings as the key, the default
    // confuses i18next when our string contains a '.'. We therefore disable key
    // separators.
    //
    // See:
    //  * https://www.i18next.com/overview/configuration-options#misc
    keySeparator: false,

    // Namespace separators allow us to have multiple translation namespaces.
    // The default namespace separator is ':'. We do not use namespaces, so we
    // do not need this separator. Disabling it makes it easier to use colon in
    // messages.
    //
    // See:
    //  * https://www.i18next.com/overview/configuration-options#misc
    nsSeparator: false,
  });


export default i18n;