import React from 'react';

import BasePage from './BasePage';
import './BaseContainer.css'


interface Props {
  className?: string,
  header?: string,
  contentProgress?: React.ReactNode,
  children: React.ReactNode
}

function BaseContainer({className = '', header = '', contentProgress = null, children}: Props): React.ReactElement {
  return (
    <BasePage>
      <div className={`BaseContainer ${className}`}>
        <h2 className="BaseContainer-header">{header}</h2>
        {contentProgress}
        <div className="BaseContainer-frame">
          {children}
        </div>
      </div>
    </BasePage>
  )
}

export default BaseContainer
