import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkmark from '../components/Checkmark';
import ContentContainer from '../components/ContentContainer';
import OrganizationWebsiteLink from '../components/OrganizationWebsiteLink';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './PasswordChangedPage.css';


function PasswordChangedPage(): React.ReactElement {
  const { t } = useTranslation<string>();

  useDocumentTitle({title: 'Password Changed'});

  return <>
    <ContentContainer
      footer={
        <div className="PasswordChangedPage-bottom-container">
          <OrganizationWebsiteLink />
        </div>
      }
      header={t('New password')}
      title={t('Password changed')}
    >
      <div className="PasswordChangedPage-info">
        <p>{t('The account will be ready for login in two to three minutes.')}</p>
        <p>{t('You can close this window.')}</p>
      </div>
      <Checkmark />
    </ContentContainer>
  </>
}

export default PasswordChangedPage;
