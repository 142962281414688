import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import {
  sendInvitationCode,
} from '../api/Invitation';
import Button from '../components/Button';
import ButtonSecondary, { SecondaryButtonType } from '../components/ButtonSecondary';
import { Contacts } from '../components/ContactAndHelpModal'
import ContentContainer from '../components/ContentContainer';
import Modal from '../components/Modal';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import {
  InvitationParam,
  withInvitation,
} from '../invitationhelper';
import './LoginLinkWelcomePage.css';
import { useURLHelper } from '../urlhelper';


interface WrongNumberOrNoPhoneModalProps {
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
}
function WrongNumberOrNoPhoneModal({ isOpen, onRequestClose }: WrongNumberOrNoPhoneModalProps) {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  const orgName = org.name.localize();

  return <Modal
    id="wrong-number-or-no-phone"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={t('Contact helpdesk')}
  >
    <p>{t('To change your phone number or log in without a phone, contact the helpdesk at {{orgName}}.', { orgName })}</p>
    <Contacts />
  </Modal>
}


function LoginLinkWelcomePage({ invitation }: InvitationParam): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  const [showModal, setShowModal] = useState(false);
  const urlHelper = useURLHelper();
  useDocumentTitle({title: 'Activate Account'});
  
  const orgName = org.name.localize();

  async function sendCode() {
    const params = new URLSearchParams();
    try {
      await sendInvitationCode(org.backendId, invitation.id, 'sms');
      params.set('invitation', invitation.id);
      urlHelper.redirectPush(`/${org.id}/invitation-verify?${params.toString()}`);
    } catch (e) {
      //Error sending SMS message
      params.set('invitation', invitation.id);
      params.set('sendingSmsError', 'true')
      urlHelper.redirectPush(`/${org.id}/invitation-verify?${params.toString()}`);
    }
  }

  return <ContentContainer className="LoginLinkWelcomePage" header={t('Log in')} title={t('Welcome to {{orgName}}', { orgName })} currentStep={0}>
    <p>{t('Here you can activate your user account and set a password.')}</p>
    <p>{t('Verify yourself with a code that is sent to the mobile number we have registered.')}</p>
    <p className="LoginLinkWelcomePage-number">{invitation.anonymizedPhoneNumber}</p>
    <div className="LoginLinkWelcomePage-send">
      <Button fullWidth onClick={sendCode}>
        <div className="LoginLinkWelcomePage-send-text">{t('Send verification code on SMS')}</div>
      </Button>
      <ButtonSecondary buttonText={t('Wrong number, or cannot use mobile')} onClick={(e) => {setShowModal(true); e.preventDefault(); return false;}} type={SecondaryButtonType.button} fullWidth />
    </div>
    <WrongNumberOrNoPhoneModal isOpen={showModal} onRequestClose={() => {setShowModal(false);}} />  
  </ContentContainer>;
}

export default withInvitation(LoginLinkWelcomePage);
