import { Hash, Location, NavigateFunction, Pathname, Search, useNavigate } from 'react-router-dom';

import { useOrganization } from './OrganizationContext';
import Organization from './api/Organization';


interface LoginParameters {
  next: string;
}

interface LocationDescriptorObject<S = Location> {
  pathname?: Pathname;
  state?: S;
  search?: Search;
  hash?: Hash;
}

class URLHelper {
  history: NavigateFunction;
  org: Organization;

  constructor() {
    this.history = useNavigate();
    this.org = useOrganization();
  }

  /**
   * redirectPush redirects to the given locaiton, appending it to the browser
   * history.
   *
   * @param to Location to redirect to.
   */
  redirectPush(to: string): void {
    this.history(to);
  }

  /**
   * redirectReplace redirects to the given locaiton, replacing the current page
   * in the browser history.
   *
   * @param to Location to redirect to.
   */
  redirectReplace(to: string): void {
    this.history(to, {replace: true });
  }

  goBack(): void {
    this.history(-1);
  }

  genericError(details?: string): void {
    this.history(
      {
        pathname: `/${this.org.id}/error`,
      },
      {state: {
        details: details,
      },
      },
    )
  }

  errorVerifyingSmsCode(details?: string, invitationId?: string): void {
    this.history(
      {
        pathname: `/${this.org.id}/error`,
        search: '?error=errorverifyingsmscode',
      },
      {state: {
        details: details,
        invitationId: invitationId,
      },
      },
    )
  }

  noUserAccountToActivateError(): LocationDescriptorObject<Location> {
    return {
      pathname: `/${this.org.id}/error`,
      search: '?error=nouseraccounttoactivate',
    };
  }

  accountNotReadyToActivateError(): LocationDescriptorObject<Location> {
    return {
      pathname: `/${this.org.id}/error`,
      search: '?error=accountnotreadytoactivate',
    }
  }

  activate(): Pathname {
    return (
      `/${this.org.id}/activate`
    );
  }

  login({ next }: LoginParameters): LocationDescriptorObject<Location> {
    const params = new URLSearchParams({'next': next});
    return {
      pathname: `/${this.org.id}/login`,
      search: '?' + params.toString(),
    }
  }

  passwordChange(): Pathname {
    return (
      `/${this.org.id}/pwchange`
    )
  }

  profile(): Pathname {
    return (
      `/${this.org.id}/profile`
    );
  }

}

export function useURLHelper(): URLHelper {
  return new URLHelper();
}
