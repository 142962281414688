import React from 'react';
import './Checkbox.css';


interface Props {
  id: string;
  onChange: (id: string, checked: boolean) => void;
  disabled?: boolean;
}

function Checkbox({ id, onChange, disabled = false }: Props): React.ReactElement {

  return (
    <label className="Checkbox-container">
      <input id={id} type="checkbox" disabled={disabled} onChange={(event) => onChange(id, event.target.checked)} />
      <span className="Checkbox-checkmark"></span>
    </label>
  );

}

export default Checkbox;