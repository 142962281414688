import React, { useEffect } from 'react';

import { useAccountName } from './AccountContext';
import { useOrganization } from './OrganizationContext';
import LoadingSkeleton, { PageType } from './components/LoadingSkeleton';
import ChangeImagePage from './pages/ChangeImagePage';
import ChangePinPage from './pages/ChangePinPage';
import { AccountNotActivated, ErrorLoadingData } from './pages/ErrorPage';
import OrderAccessCardPage from './pages/OrderAccessCardPage';
import ProfilePage from './pages/ProfilePage';
import StartPage from './pages/StartPage';

/*
 This function is the handler for the Profile Page and the sub pages: Image, Accesscard, Pin.
 Users must be logged in to enter these pages and we redirect to Feide
 if the user is not logged in.
*/

interface Props {
  destinationPage: string;
}

function ProfileHandler({destinationPage}: Props): React.ReactElement {
  const {account, error, isLoading, redirectOnError} = useAccountName();
  const org = useOrganization();
  
  useEffect(() => {
    if(error){
      redirectOnError(error, 'profile'); 
    }
  }, [error])

  if(!account && !error && !isLoading) {
    return <ErrorLoadingData />
  }

  if (!account) {
    // Account not loaded yet, displaying a loading skeleton
    return <LoadingSkeleton pageType={PageType.Subpage} />
  }

  if (!account.activated) {
    return <AccountNotActivated />
  }

  if (destinationPage === 'accesscard' && org.profilePageOptions.orderAccessCard()) {
    return <OrderAccessCardPage account={account} />
  }

  if (destinationPage === 'pincode' && org.profilePageOptions.changePincode()) {
    return <ChangePinPage account={account}/> 
  }

  if (destinationPage === 'image' && org.profilePageOptions.uploadImage()) {
    return <ChangeImagePage account={account} />
  }

  if (destinationPage === 'profile') {
    return <ProfilePage account={account} /> 
  }  

  return <StartPage />

}

export default ProfileHandler;