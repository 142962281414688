import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useOptionalOrganization } from '../OrganizationContext';
import GenericContactModal from './ContactAndHelpModal';

import './BottomBar.css';


function BottomBar(): React.ReactElement {
  const org = useOptionalOrganization();

  const { t } = useTranslation<string>();
  const [showContact, setShowContact] = useState(false);
  const [showAccessibilityLink, setShowAccessibilityLink] = useState(false);

  const showModal = () => {
    setShowContact(true);
  };

  const hideModal = () => {
    setShowContact(false);
  };

  let supportLink = <></>;
  let contactModal = <></>;
  if (org) {
    supportLink = <button className='supportBtn' onClick={showModal}>{t('Contact and help')}</button>
    contactModal = <GenericContactModal title={t('Contact and help')} show={showContact} handleClose={hideModal} />
  }

  const accessibilityLink = <a className='BottomBar-accessibility' target='_blank' rel='noreferrer' href='https://uustatus.no/nb/erklaringer/publisert/3c6212a5-ad4d-44f0-97cc-df406439ce64'>{t('Accessibility statement')}</a>
  // Only show accessibility link in 'SelectOrgPage' and 'StartPage'
  const location = useLocation();
  useEffect( () => {
    if (location.pathname=='/') {
      setShowAccessibilityLink(true)
    } else if (org && (location.pathname==`/${org.id}/` || location.pathname==`/${org.id}`)) {
      setShowAccessibilityLink(true)
    }
  }, [])

  return (
    <footer className="BottomBar">
      {contactModal}
      {supportLink}
      <div>
        { showAccessibilityLink && accessibilityLink }
      </div>
    </footer>
  );
}

export default BottomBar;
