import React, { useEffect, useState } from 'react';

import { useAccountName } from './AccountContext';
import LoadingSkeleton, { PageType } from './components/LoadingSkeleton';
import { AccountNotActivated, ErrorLoadingData } from './pages/ErrorPage';
import PasswordChangedPage from './pages/PasswordChangedPage';
import SetPasswordPage from './pages/SetPasswordPage';


function PasswordChange(): React.ReactElement {
  const { account, error, isLoading, redirectOnError} = useAccountName();
  const [passwordChanged, setPasswordChanged] = useState(false);
  
  useEffect(() => {
    if(error){
      redirectOnError(error, 'pwchange'); 
    }
  }, [error])

  if(!account && !error && !isLoading) {
    return <ErrorLoadingData />
  }

  // Account not loaded yet, displaying a loading skeleton
  if (!account) {
    return <LoadingSkeleton pageType={PageType.Subpage} />
  }
  
  if (!account.activated) {
    return <AccountNotActivated />;
  }

  const onPasswordChanged = () => {
    setPasswordChanged(true);
  }

  if (!passwordChanged) {

    return<SetPasswordPage account={account} onCompleted={onPasswordChanged} />;
  } else {
    return <PasswordChangedPage />
  }
  
}

export default PasswordChange;
