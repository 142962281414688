import React, {
  useEffect,
  useState,
} from 'react';
import {
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';

import { WithAccount } from './AccountContext';
import Activate from './Activate';
import { WithOrganization } from './OrganizationContext';
import PasswordChange from './PasswordChange';
import ProfileHandler from './ProfileHandler';
import {
  Organization,
  OrganizationNotFoundError,
  getOrganization,
} from './api/Organization'
import LoadingSkeleton, { PageType } from './components/LoadingSkeleton';
import {
  ErrorPage,
  PageNotFound,
} from './pages/ErrorPage';
import LoggedOutPage from './pages/LoggedOutPage';
import LoginLinkVerifyCodePage from './pages/LoginLinkVerifyCodePage';
import LoginLinkWelcomePage from './pages/LoginLinkWelcomePage';
import LoginPage from './pages/LoginPage';
import PasswordChangeEmailSentPage from './pages/PasswordChangeEmailSentPage';
import PasswordChangeHasNINPage from './pages/PasswordChangeHasNINPage';
import PasswordChangeWithoutIDPorten from './pages/PasswordChangeWithoutIDPorten';
import StartPage from './pages/StartPage';


type Params = {
  orgId: string
}

function OrgRouter(): React.ReactElement {
  const { orgId } = useParams<Params>();
  const [pending, setPending] = useState<boolean>(true);
  const [org, setOrg] = useState<Organization>();
  const location = useLocation();

  async function initOrg() {
    try {
      setOrg(await getOrganization(orgId as string));
    } catch (err) {
      if (err instanceof OrganizationNotFoundError) {
        console.log('Unknown organization:', orgId);
      } else {
        throw err;
      }
    } finally {
      setPending(false);
    }
  }
  useEffect(() => { initOrg(); }, [orgId]);
  
  // Still loading org, displaying a loading skeleton
  if (pending) {
    // Getting the users path to know what skeleton to display
    const path = location.pathname.split('/')[2]
    if (path === '' || path === undefined) {
      return <LoadingSkeleton pageType={PageType.Startpage} />
    }
    return <LoadingSkeleton pageType={PageType.Subpage} />
  }

  if (!org) {
    // Unknown organization
    return <PageNotFound />;
  }

  const favicon = (document.getElementById('favicon') as HTMLAnchorElement);
  favicon.href = `${process.env.PUBLIC_URL}/static/logo/${org.favicon}`


  return (
    <WithOrganization org={org}>
      <WithAccount org={org}>
        <Routes>
          <Route path={'/'} element={<StartPage/> }/>
          <Route path={'activate'} element={<Activate/>}/>
          <Route path={'invitation'} element={<LoginLinkWelcomePage/>}/>
          <Route path={'invitation-verify'} element={<LoginLinkVerifyCodePage/>}/>
          <Route path={'loggedout'} element={<LoggedOutPage/>}/>
          <Route path={'login'} element={<LoginPage/>}/>
          <Route path={'pwchange'} element={<PasswordChange/>}/>
          <Route path={'pwchange-email-sent'} element={<PasswordChangeEmailSentPage/>}/>
          <Route path={'pwchange-without-idporten'} element={<PasswordChangeWithoutIDPorten/>}/>
          <Route path={'pwchange-use-idporten'} element={<PasswordChangeHasNINPage/>}/>
          <Route path={'profile'} element={<ProfileHandler destinationPage='profile' />}/>
          <Route path={'accesscard'} element={<ProfileHandler destinationPage='accesscard' />}/>
          <Route path={'image'} element={<ProfileHandler destinationPage='image'/>}/>
          <Route path={'pincode'} element={<ProfileHandler destinationPage='pincode' />}/>
          <Route path={'error'} element={<ErrorPage/>}/>
          <Route path={'*'} element={<PageNotFound />} />
        </Routes>
      </WithAccount>
    </WithOrganization>
  );
}

export default OrgRouter;
