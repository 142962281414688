import React from 'react';

import './LoadingSpinner.css';


interface props {
  ariaLabel: string;
}

export default function LoadingSpinner({ ariaLabel }: props): React.ReactElement {
  return (
    <div aria-live='polite' role='alert'>
      <span className="loadingSpinner" aria-label={ariaLabel}></span>
    </div>
  )
}
