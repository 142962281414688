import React from 'react';

import BaseContainer from './BaseContainer';
import ContentProgress from './ContentProgress';

import './ContentContainer.css';


interface Props {
  className?: string;
  footer?: React.ReactNode;
  header?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  currentStep?: number;
}

function ContentContainer({ className, footer, header = '', title = '', children = null, currentStep }: Props): React.ReactElement {
  className = `ContentContainer ${className}`
  if (footer) {
    footer = <>
      <div className="ContentContainer-flex"/>
      <div className="ContentContainer-footer">
        {footer}
      </div>
    </>;
  }
  let contentProgress;
  if (currentStep !== undefined) {
    contentProgress = <ContentProgress currentStep={currentStep} steps={8}/>;
  }
  return (
    <BaseContainer
      className={className}
      header={header}
      contentProgress={contentProgress}
    >
      <div className="ContentContainer-body">
        <h3 className="ContentContainer-title">{title}</h3>
        {children}
      </div>
      {footer}
    </BaseContainer>
  );
}

export default ContentContainer;
