import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import IDPorten from './idporten.svg'

import './IDPortenLoginButton.css';


interface IDPortenLoginButtonProps {
  returnTo: string;
}
function IDPortenLoginButton({returnTo}: IDPortenLoginButtonProps): React.ReactElement {
  const { t } = useTranslation<string>();

  const idPortenLink = '/api/auth-idporten?' + (new URLSearchParams({'return': returnTo})).toString();

  return (
    <Button fullWidth linkTo={idPortenLink}>
      <span className='IDPortenLoginButton'>
        <span id='item'>
          <img src={IDPorten} alt=''></img>
        </span>
        <span id='item'>
          {t('Log in using ID-porten')}
        </span>
      </span>
    </Button>
  );
}

export default IDPortenLoginButton;
