import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';


interface Props {
  title: string;
  language?: string | false;
}

export function useDocumentTitle({title, language = false}:Props):void {
  const defaultTitle = useRef(document.title)
  const { t } = useTranslation();
  const org = useOrganization();
  const orgName = org.name.localize();
  const translatedTitle = t('{title}', title)
  const orgAdjustedTranslatedTitle = org ? `${orgName} - ${translatedTitle}` : t('Account Claim - {{title}}', title)

  useEffect(() => {
    if(!language) {
      document.title = orgAdjustedTranslatedTitle;
    }
  }, [title])

  useEffect(() => {
    if(language) {
      document.title = defaultTitle.current;
    }
  }, [language])
  
}