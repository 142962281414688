import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkmark from './checkmark.svg';

import './PasswordInput.css';


interface Props {
  checkmark: boolean;
  children: React.ReactNode;
  error?: boolean;
  inputId: string;
  message: string;
  messageOk?: boolean;
  meter?: React.ReactNode;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  autoComplete?: string;
}

function PasswordInput({
  checkmark,
  children,
  error = false,
  inputId,
  message,
  messageOk = false,
  meter = <></>,
  password,
  setPassword,
  autoComplete,
}: Props): React.ReactElement {

  const { t } = useTranslation<string>();
  const [visible, setVisible] = useState(false);
  const [showError, setShowError] = useState(true);

  let inputClass = (showError && error) ? 'error' : '';
  if (checkmark && !error) {
    inputClass = 'approved';
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const password = event.target.value;
    setShowError(false);
    setPassword(password);
  }

  function onBlur(): void {
    setShowError(true);
  }

  return (
    <div className="PasswordInput">
      <div className="PasswordInput-toptext">
        <label htmlFor={inputId} className="PasswordInput-title">{children}</label>
      </div>
      <div className="PasswordInput-input">
        {checkmark && <img className="PasswordInput-checkmark" src={Checkmark} alt="" />}
        <input id={inputId} className={inputClass} type={visible ? 'text' : 'password'} value={password} onChange={onChange} onBlur={onBlur} autoComplete={autoComplete}></input>
        <button
          aria-label={t('Toggle password visibility')}
          aria-pressed={visible ? 'true' : 'false'}
          className={`PasswordInput-eye ${visible ? 'visible' : ''}`}
          onClick={() => setVisible(!visible)} />
      </div>
      {meter}
      <div className="PasswordInput-bottomtext">
        <div className={`PasswordInput-message ${messageOk ? 'ok': ''}`}><strong>{message}</strong></div>
      </div>
    </div>
  );

}

export default PasswordInput;
