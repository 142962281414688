import React from 'react';

import Button from './Button';

import './LoadingSkeleton.css'


function SkeletonTopBar(): React.ReactElement {
  return (
    <div className='LoadingSkeleton-topbar' />
  )
}

export enum PageType {
  Startpage,
  Subpage,
}
interface Props {
  pageType: PageType,
}
export default function LoadingSkeleton( { pageType } :Props): React.ReactElement {

  //Display a skeleton of the startpage
  if (pageType === PageType.Startpage) {
    return (
      <div>
        <SkeletonTopBar />
        <div className='LoadingSkeleton-startpage-buttons'>
          <Button big disabled linkTo=''> </Button>
          <Button big disabled linkTo=''> </Button>
          <Button big disabled linkTo=''> </Button>
        </div>
      </div>
    )
  }

  // Display a skeleton of the subpages
  return (
    <div>
      <SkeletonTopBar />
      <div className='LoadingSkeleton-subpage-container'>
        <div className='LoadingSkeleton-subpage-container-frame'>
          <div className='LoadingSkeleton-subpage-title'/>
          <div className='LoadingSkeleton-subpage-text' />
          <div className='LoadingSkeleton-subpage-text' />
          <div className='LoadingSkeleton-subpage-text' />
          <div className='LoadingSkeleton-subpage-text' />
          <div className='LoadingSkeleton-subpage-button' />
        </div>
      </div>
    </div>
  )
}