import React from 'react';
import { useTranslation } from 'react-i18next';

import BasePage from '../components/BasePage';
import Button from '../components/Button';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';

import './StartPage.css';


function StartPage(): React.ReactElement  {
  const urlHelper = useURLHelper();
  const { t } = useTranslation<string>();

  useDocumentTitle({title: 'Home'});

  return (
    <BasePage>
      <div className="StartPage">
        <Button big linkTo={urlHelper.activate()}>{t('Activate account')}</Button>
        <Button big linkTo={urlHelper.passwordChange()}>{t('Forgot password')}</Button>
        <Button big linkTo={urlHelper.profile()}>{t('My Account')}</Button>
      </div>
    </BasePage>
  );
}

export default StartPage;
