import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import { OrderingAccessCardError,initOrderAccessCard } from '../api/AccessCard';
import Account from '../api/Account';
import { MissingImageError } from '../api/Image';
import ButtonSecondary, { SecondaryButtonType, SecondaryButtonVariant } from '../components/ButtonSecondary';
import Checkmark from '../components/Checkmark';
import ContentContainer from '../components/ContentContainer';
import './OrderAccessCardPage.css'
import ErrorMessage from '../components/ErrorMessage';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import { useURLHelper } from '../urlhelper';


interface PickupLocationProps {
  disabled: boolean;
  locations: string[];
  setPickupLocation: (pickupLocation: string) => void;
}
function PickupLocationDropdown({ disabled, locations, setPickupLocation }: PickupLocationProps): React.ReactElement {
  const {t} = useTranslation();
  return(
    <div className='OrderAccessCardPage-dropdown-container'>
      <p>{t('Select your pick-up location from the list below:')}</p>
      <select className='OrderAccessCardPage-dropdown-select' onChange={(e) => setPickupLocation(e.target.value)} disabled={disabled}>
        <option hidden></option>
        {locations.map(location =>
          <option key={location} value={location}>
            {location}
          </option>,
        )};        
      </select>
    </div>
  );
}

interface Props {
  account: Account;
}
function OrderAccessCardPage({account}:Props): React.ReactElement {
  const org = useOrganization();
  const {t} = useTranslation<string>();
  const urlHelper = useURLHelper();
  const [pickupLocation, setPickupLocation] = useState<string>('');
  const [disableDropdown, setDisableDropdown] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [successOrderingCard, setSuccessOrderingCard] = useState<boolean>(false)
  const [errorOrderingCard, setErrorOrderingCard] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const orgname = org.name.localize();
  const locations: string[] = org.accessCardPickupLocations ? org.accessCardPickupLocations : [org.name.localize()]
  const pickupLocationText = org.accessCardMessage ? org.accessCardMessage.localize() : ''

  useDocumentTitle({title: 'Order Access Card'});

  // Function to order the selected access card.
  async function orderAccessCard() {
    setDisableButton(true);
    setDisableDropdown(true);
    try {
      await initOrderAccessCard({
        orgID: org.backendId,
        accountID: account?.id,
        pickupLocation: pickupLocation,
      })
      setSuccessOrderingCard(true);
    } catch (e) {
      setErrorOrderingCard(true)
      if (e instanceof OrderingAccessCardError) {
        setErrorMessage('You have an existing order for a new access card, which was ordered less than 10 days ago.');
      } else if (e instanceof MissingImageError) {
        setErrorMessage('You must first upload an image to your account before you can order an access card.');
      } else {
        // Unknown error
        urlHelper.genericError((e as Error).message);
        return
      }
    }
  }
  
  useEffect(() => {
    if (pickupLocation != '') {
      setDisableButton(false)
    }
  }, [pickupLocation])

  return (
    <ContentContainer
      header={t('Access card')}
      title={t('Order access card')}
      footer={
        <div className='OrderAccessCardPage-footer'>
          <ButtonSecondary onClick={() => orderAccessCard()} disabled={disableButton} type={SecondaryButtonType.button} variant={SecondaryButtonVariant.contained} buttonText={t('Order')} />
          <ButtonSecondary linkTo={`/${org.id}/profile`} type={SecondaryButtonType.link} variant={SecondaryButtonVariant.contained} buttonText={t('Back')} />
        </div>
      }
    >
      <p>{ org.imageRequirementsLink && <Trans>Before ordering a new access card make sure that your <a target='_blank' rel='noopener noreferrer' href={org.imageRequirementsLink.localize()}>photo meets the requirements</a>.</Trans>}</p>
      <p>{ org.accessCardRulesLink && <Trans>Read the <a target='_blank' rel='noopener noreferrer' href={org.accessCardRulesLink.localize()}>access card guidelines</a> for {{orgname}}.</Trans>}</p>
      <p>{ pickupLocationText }</p>
      <PickupLocationDropdown disabled={disableDropdown} locations={locations} setPickupLocation={setPickupLocation}/>
      {
        successOrderingCard &&
        <div className='OrderAccessCardPage-SuccessOrderingCard'>
          <Checkmark />
          <p>
            {t('Your card was successfully ordered.')}
          </p>
        </div>
      }
      <div className='OrderAccessCardPage-ErrorMessage'>
        <ErrorMessage showError={errorOrderingCard} message={errorMessage} />
      </div>
     

    </ContentContainer>
  )

}

export default OrderAccessCardPage;