export class ConfigAccountUserError extends Error {
  name = 'ConfigAccountUserError';
}
export class PasswordFoundInBreachError extends Error {
  name = 'PasswordFoundInBreachError';
}
export class ContextBasedPasswordError extends Error {
  name = 'ContextBasedPasswordError';
}
export class PasswordHasBeenUsedError extends Error {
  name = 'PasswordHasBeenUsedError';
}
export class PasswordCriteriaNotMetError extends Error {
  name = 'PasswordCriteriaNotMetError';
}

interface ConfigAccountParams {
  activate: boolean;
  password: string;
  orgID: string;
  accountID: string;
}

export async function configAccount({activate, password, orgID, accountID}: ConfigAccountParams): Promise<void> {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: accountID,
      activate: activate,
      password: password,
    }),
  };
  const res = await fetch(`/api/accounts/${orgID}/config`, requestOptions);
  const statusData = (await res.text()).trim();
  if (res.ok) {
    return;
  } else if (res.status===400 && statusData==='Password found in breach') {    
    throw new PasswordFoundInBreachError;
  } else if (res.status===400 && statusData==='Context based password not allowed' ) {
    throw new ContextBasedPasswordError;
  } else if (res.status===400 && statusData==='The password has already been used for this account') {
    throw new PasswordHasBeenUsedError;
  } else if (res.status===400 && statusData==='Password criteria not met') {
    throw new PasswordCriteriaNotMetError;
  } else if(res.status === 400) {
    // Error caused by something the user did (e.g. reusing an old password).
    throw new ConfigAccountUserError(statusData);
  } else {
    // Some other error when calling the backend.
    throw new Error(res.status + ' ' + res.statusText + ': ' + statusData);
  }
}
