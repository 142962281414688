import React from 'react';
import { useTranslation } from 'react-i18next';

import BritainFlag from './britainFlag.svg';
import NorwegianFlag from './norwegianFlag.svg';

import './LanguageSelector.css';


interface Props {
  short?: boolean;
}

function LanguageSelector({short = false}: Props): React.ReactElement {
  const {i18n} = useTranslation<string>();

  let newLanguage = 'nb';
  let newLanguageLabel = short ? 'NB' : 'Norsk bokmål';
  let flag = NorwegianFlag;
  
  
  document.documentElement.lang = i18n.language;
  
  if (i18n.language === 'nb') {
    newLanguage = 'en';
    newLanguageLabel = short ? 'EN' : 'English';
    flag = BritainFlag;
  }
  
  const switchLanguage = () => {
    document.documentElement.lang = newLanguage;
    i18n.changeLanguage(newLanguage);
    return false;
  };


  return (
    <a className="LanguageSelector" aria-label={i18n.t('Switch language')} href="#" onClick={switchLanguage}>
      <img className="LanguageSelector-image" src={flag} alt={i18n.t('Language choice')}/>
      {newLanguageLabel}
    </a>
  );
}

export default LanguageSelector;
