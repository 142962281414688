import React from 'react';


const simplifiedLinkContext = React.createContext<string>('');

function simplifyLink(link: string): string {
  link = link.replace(/^https?:\/\//i, ''); // Remove leading scheme
  link = link.replace(/^www\./i, ''); // Remove `www.` from domain
  link = link.replace(/\/$/, ''); // Remove trailing slash
  return link;
}

export function SimplifiedLinkText(): React.ReactElement {
  const href = React.useContext(simplifiedLinkContext);
  return <>{simplifyLink(href)}</>;
}

interface SimplifiedLinkProps {
  href: string;
  children?: React.ReactNode;
}

export function SimplifiedLink({href, children}: SimplifiedLinkProps): React.ReactElement {
  if (!children) {
    children = <SimplifiedLinkText />;
  }
  return (
    <simplifiedLinkContext.Provider value={href}>
      <a target='_blank' rel='noopener noreferrer' href={href}>
        {children}
      </a>
    </simplifiedLinkContext.Provider>
  );
}

export default SimplifiedLink;
