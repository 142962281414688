import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAccountName } from '../AccountContext';
import { useOrganization } from '../OrganizationContext';
import {
  FailedToMakeCallError,
  FailedToSendSmsMessageError,
  InvalidPhoneNumberError,
  MaxAttemptsReachedError,
  WrongVerificationCodeError,
  sendInvitationCode,
  verifyInvitationCode,
} from '../api/Invitation';
import ButtonSecondary, { SecondaryButtonType } from '../components/ButtonSecondary';
import Checkmark from '../components/Checkmark';
import { Contacts } from '../components/ContactAndHelpModal'
import ContentContainer from '../components/ContentContainer';
import ErrorMessage from '../components/ErrorMessage';
import LoadingSpinner from '../components/LoadingSpinner';
import Modal from '../components/Modal';
import VerificationInputField, { VerificationStatus } from '../components/VerificationCodeInputField';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';
import {
  InvitationParam,
  withInvitation,
} from '../invitationhelper';
import { useURLHelper } from '../urlhelper';
import './LoginLinkVerifyCodePage.css';


interface ProblemReceivingCodeModalProps {
  isOpen: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
}
function ProblemReceivingCodeModal({ isOpen, onRequestClose }: ProblemReceivingCodeModalProps) {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  
  const orgName = org.name.localize();

  return <Modal
    id="problem-receiving-code"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={t('Problems receiving code')}
  >
    <p>{t('Contact the helpdesk at {{orgName}}.', { orgName })}</p>
    <Contacts />
  </Modal>
}

enum ButtonToLoad {
  None,
  Sms,
  Call,
}

interface VerificationStatusLineProps {
  status: VerificationStatus;
}
function VerificationStatusLine({status}: VerificationStatusLineProps): React.ReactElement {
  const { t } = useTranslation<string>();
  let className = 'LoginLinkVerifyCodePage-code-status';
  let text;
  switch (status) {
    case VerificationStatus.Verifying:
      text=t('Verifying code...');
      break;
    case VerificationStatus.Error:
      text=t('Incorrect code');
      className += ' LoginLinkVerifyCodePage-code-status-incorrect';
      break;
    case VerificationStatus.Success:
      text=t('Correct code');
      className += ' LoginLinkVerifyCodePage-code-status-correct'
  }
  return <span className={className}>{text}&nbsp;</span>;
}

function LoginLinkVerifyCodePage({ invitation }: InvitationParam): React.ReactElement {
  const org = useOrganization();
  const {fetchAccount} = useAccountName();
  const { t } = useTranslation<string>();
  const [verificationStatus, setVerificationStatus] = useState(VerificationStatus.Idle);
  const urlHelper = useURLHelper();
  const [showModal, setShowModal] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState<ButtonToLoad>(ButtonToLoad.None);
  const [disableButton, setDisableButton] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();

  useDocumentTitle({title: 'Verify Account'});

  // TODO: Check if a SMS has been sent for this invitation. (If the user
  // session expires, the user will get an error otherwise.)


  // Display errormessage if the initial SMS message returned an error
  const params = new URLSearchParams(location.search)
  const sendingSmsError = params.get('sendingSmsError')
  useEffect(() => {
    if (sendingSmsError === 'true') {
      setErrorMessage('An error occurred while sending SMS. Please try the call function, or contact helpdesk if the problem persists.')
      setVerificationError(true)
    }
  }, [])

  async function verifyCode(code: string) {
    setVerificationStatus(VerificationStatus.Verifying);
    try {
      await verifyInvitationCode(org.backendId, code);
      setVerificationStatus(VerificationStatus.Success);
      setVerificationError(false);
    } catch (e: unknown) {
      if (e instanceof WrongVerificationCodeError) {
        setVerificationStatus(VerificationStatus.Error);
      } else {
        // Unknown error.
        urlHelper.errorVerifyingSmsCode((e as Error).message, invitation.id)
      }
      return;
    }

    // At this point we are logged in. Fetch the account:
    const account = await fetchAccount();

    // Where we go next depend on the account.
    setTimeout(() => {
      if (!account?.activated) {
        // If it isn't activated, we take the user to the account activation page.
        urlHelper.redirectPush(`/${org.id}/activate`);
      } else {
        // Otherwise, we take the user to the password change page.
        urlHelper.redirectPush(`/${org.id}/pwchange`);
      }
    }, 1000)
  }

  async function sendSmsOrCall(method: string, button: ButtonToLoad) {
    setVerificationError(false);
    setDisableButton(true);
    setLoadingSpinner(button)
    try {
      await sendInvitationCode(org.backendId, invitation.id, method);
    } catch (e) {
      if (e instanceof FailedToSendSmsMessageError) {
        setErrorMessage('We can’t send SMS to your phone number. Try the call function or contact helpdesk if the problem persists.');
      } else if (e instanceof FailedToMakeCallError) {
        setErrorMessage('We can’t call your phone number. Try SMS or contact helpdesk if the problem persists.');
      } else if (e instanceof InvalidPhoneNumberError) {
        setErrorMessage('Your phone number is not formatted correctly. Please contact helpdesk to update your number.');
      } else if (e instanceof MaxAttemptsReachedError) {
        setErrorMessage('You have reached the maximum number of attempts. Please try again in 10 minutes.')
      } else {
        setErrorMessage('We can’t reach your phone number. Please contact helpdesk if the problem persists.')
      }
      setVerificationError(true);
    } finally {
      setTimeout(setDisableButton, 2000, false);
      setTimeout(setLoadingSpinner, 2000, ButtonToLoad.None);
    }
  }

  return <ContentContainer className="LoginLinkVerifyCodePage" header={t('Log in')} title={t('Verify by SMS code')} currentStep={0}>
    <p>{t('Enter the temporary code we\'ve sent to your mobile phone number')}:</p>
    <p className="LoginLinkVerifyCodePage-number">{invitation.anonymizedPhoneNumber}</p>
    <div aria-live="polite" role="status">
      <ErrorMessage message={errorMessage} showError={verificationError} />
      { verificationStatus == VerificationStatus.Success ? <div className="LoginLinkVerifyCodePage-Checkmark"><Checkmark/></div> : <div/>}
    </div>
    <VerificationInputField length={5} onCompleted={(code) => verifyCode(code)} status={verificationStatus} inputType='verification' updateStatus={(status) => setVerificationStatus(status)} />
    <div aria-live="polite" role="status">
      <VerificationStatusLine status={verificationStatus}/>
    </div>
    <div className="LoginLinkVerifyCodePage-ButtonContainer">
      <ButtonSecondary 
        buttonText={t('Resend code')} 
        onClick={(e) => {sendSmsOrCall('sms', ButtonToLoad.Sms); e.preventDefault(); return false;}} 
        type={SecondaryButtonType.button} 
        fullWidth 
        disabled={disableButton} >{loadingSpinner===ButtonToLoad.Sms && <LoadingSpinner ariaLabel={t('Resending code')}/>}</ButtonSecondary>
      <ButtonSecondary
        buttonText={t('Call me with code')}
        onClick={(e) => {sendSmsOrCall('call', ButtonToLoad.Call); e.preventDefault(); return false}}
        type={SecondaryButtonType.button}
        fullWidth
        disabled={disableButton} >{loadingSpinner===ButtonToLoad.Call && <LoadingSpinner ariaLabel={t('Calling')}/>}</ButtonSecondary>
      <ButtonSecondary buttonText={t('Having trouble getting a code?')} onClick={(e) => {setShowModal(true); e.preventDefault(); return false;}} type={SecondaryButtonType.button} fullWidth/>
    </div>
    <ProblemReceivingCodeModal isOpen={showModal} onRequestClose={() => {setShowModal(false);}} />
  </ContentContainer>;
}

export default withInvitation(LoginLinkVerifyCodePage);
