import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import ActivationStageContainer from '../components/ActivationStageContainer';
import Button from '../components/Button';
import Checkbox from '../components/Checkbox';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './ConsentStage.css';


interface Props {
  onCompleted: () => void;
  onBack: () => void;
}

function ConsentStage({ onCompleted, onBack }: Props): React.ReactElement {

  const { t } = useTranslation<string>();
  const [consented, setConsented] = useState<boolean[]>([false, false]);
  useDocumentTitle({title: 'Consent ICT regulations'})

  function sectionChange(id: string, checked: boolean) {
    switch (id) {
      case 'section-0':
        setConsented([checked, consented[1]]);
        break;
      case 'section-1':
        setConsented([consented[0], checked]);
        break;
    }
  }

  function buttonDisable(): boolean {
    for (let i = 0; i < consented.length; i++) {
      if (consented[i] === false) {
        return true;
      }
    }
    return false;
  }

  const org = useOrganization()
  const uniFormatted = org.formattedName;
  const ictRegulations = org.ictRegulations;
  const privacyStatement = org.privacyStatement

  const btn_next = <Button onClick={onCompleted} disabled={buttonDisable()}>{t('Confirm')}</Button>;
  const btn_back = <Button onClick={onBack}>{t('Back')}</Button>
  return (
    <ActivationStageContainer title={t('Security statement')} currentStep={6} btn_next={btn_next} btn_back={btn_back}>
      <div className="ConsentStage-section">
        <Checkbox id='section-0' onChange={sectionChange}></Checkbox>
        <label className="ConsentStage-section-label" htmlFor="section-0">{t('I acknowledge my duties to')}:</label>
        <p>- <Trans>Comply with the introduced <a target="_blank" rel="noopener noreferrer" href={ictRegulations.localize()}>ICT regulations</a></Trans></p>
        <p>- {t('Get familiar with relevant security information')}</p>
      </div>
      <div className="ConsentStage-section">
        <Checkbox id='section-1' onChange={sectionChange}></Checkbox>
        <label className="ConsentStage-section-label" htmlFor="section-1">
          <Trans>I am aware that {{uniFormatted}} has a <a target="_blank" rel="noopener noreferrer" href={privacyStatement.localize()}>privacy statement</a> that explains how they process personal data.</Trans>
        </label>
      </div>
      <p>{t('Links will be sent to your new e-mail address after activation.')}</p>
    </ActivationStageContainer>
  );
}

export default ConsentStage;
