import i18next from 'i18next';


export class Invitation {
  constructor(
      readonly id: string,
      readonly anonymizedPhoneNumber: string,
  ) {
  }
}

export class ExpiredInvitationCodeError extends Error {
  name = 'ExpiredInvitationCodeError';
}
export class WrongVerificationCodeError extends Error {
  name = 'WrongVerificationCodeError';
}
export class UnknownInvitationCodeError extends Error {
  name = 'UnknownInvitationCodeError';
}
export class FailedToSendSmsMessageError extends Error {
  name = 'FailedToSendSmsMessageError';
}
export class FailedToMakeCallError extends Error {
  name = 'FailedToMakeCallError';
}

export class InvalidPhoneNumberError extends Error {
  name = 'InvalidPhoneNumberError'
}

export class MaxAttemptsReachedError extends Error {
  name = 'MaxAttemptsReachedError'
}

export class MissingPhoneNumberError extends Error {
  name = 'MissingPhoneNumberError'
}

export class AccountNotReadyToActivateError extends Error {
  name = 'AccountNotReadyToActivateError'
}

export type VerificationMethod = 'sms' | 'call';

interface ApiInvitation {
  id: string;
  anonymized_phone_number: string;
}

export async function lookupInvitation(orgBackendId: string, invitationId: string): Promise<Invitation> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      invitation: invitationId,
    }),
  };

  const res = await fetch(`/api/invitation/${orgBackendId}/lookup`, requestOptions);
  if (!res.ok) {
    const responseText = (await res.text()).trim()
    if (res.status === 404 && responseText === 'Unknown invitation code') {
      throw new UnknownInvitationCodeError();
    } else if(res.status === 400 && responseText === 'Expired invitation code') {
      throw new ExpiredInvitationCodeError();
    } else if(res.status === 500 && responseText === 'Missing phone number') {
      throw new MissingPhoneNumberError();
    } else if (res.status === 500 && responseText === 'Account not ready') {
      throw new AccountNotReadyToActivateError()
    } else {
      throw new Error('Error fetching invitation: ' + responseText);
    }
  }
  const invitation: ApiInvitation = await res.json();
  
  return new Invitation(
    invitation.id,
    invitation.anonymized_phone_number,
  );
}

export async function sendInvitationCode(orgBackendId: string, invitationId: string, method: string): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      invitation: invitationId,
      language: i18next.language,
      method: method,
    }),
  };

  const res = await fetch(`/api/invitation/${orgBackendId}/send`, requestOptions);
  if (!res.ok) {
    let message = await res.text();
    message = message.trim();
    if (message === 'sms error') {
      throw new FailedToSendSmsMessageError
    } else if (message === 'call error') {
      throw new FailedToMakeCallError
    } else if (message === 'invalid phone number') {
      throw new InvalidPhoneNumberError
    } else if (message === 'max attempts reached') {
      throw new MaxAttemptsReachedError
    }
    throw new Error('Error sending invitation verification code: ' + await res.text());
  }
}

export async function verifyInvitationCode(orgBackendId: string, code: string): Promise<void> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code: code,
    }),
  };

  const res = await fetch(`/api/invitation/${orgBackendId}/verify`, requestOptions);
  if (!res.ok) {
    let message = await res.text();
    message = message.trim();
    if (message === 'Wrong verification code') {
      throw new WrongVerificationCodeError();
    }
    throw new Error('Error verifying code: ' + message);
  }
}
