import {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Account,
  AccountNotReadyToActivateError,
  MissingUserError,
  NotAuthenticatedError,
  fetchAccounts,
} from './api/Account';
import Organization from './api/Organization';
import { useURLHelper } from './urlhelper';


interface UseAccountParameters {
    loginNext?: string;
    org?: Organization;
}

interface UseAccountResult {
  account: Account | null; 
  error: Error | null;
  isLoading: boolean;
  redirectOnError: (e: Error, loginNext: string) => void;
  fetchAccount: () => Promise<Account|void>;
}

/**
 * @param org
 * Get the current user account based on org
 * If the user has an account, account is returned
 * If we get an error, error is returned
 *
 * @returns The current user account, or undefined while it is loading, error, function to redirect based on error
 */

export function useAccount({ org }: UseAccountParameters): UseAccountResult {
  const urlHelper = useURLHelper();
  const navigate = useNavigate();
  const [account, setAccount] = useState<Account | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchAccount = async () => {
    setError(null);
    setAccount(null);
    if(!org){
      return;
    }

    try {
      setIsLoading(true);
      const accounts = await fetchAccounts(org.backendId);
      if (accounts.length >= 1) {
        setAccount(accounts[0]);
        return accounts[0];
      }
    } catch (e) {
      setError(e as Error); 
    } finally {
      setIsLoading(false);
    }
  };

  function redirectOnError(e: Error, loginNext: string): void{
    if (e instanceof NotAuthenticatedError && loginNext==='profile' && org)  {
      window.location.replace('/api/auth-feide?' + (new URLSearchParams({'return': `/${org.id}/profile`})));
    } else if (e instanceof NotAuthenticatedError) {
      navigate((urlHelper.login({next: loginNext})), {replace: true});
    } else if (e instanceof MissingUserError) {
      navigate(urlHelper.noUserAccountToActivateError(), {replace: true});
    } else if (e instanceof AccountNotReadyToActivateError) {
      navigate(urlHelper.accountNotReadyToActivateError(), {replace: true});
    } else {
      urlHelper.genericError('Error fetching accounts: ' + e), {replace: true};
    }
  }

  useEffect(
    () => {
      fetchAccount();
    },
    [org],
  );

  return { account, error, isLoading, redirectOnError, fetchAccount };
}
