import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import ButtonSecondary, { SecondaryButtonType, SecondaryButtonVariant } from '../components/ButtonSecondary';
import Checkmark from '../components/Checkmark';
import ContentContainer from '../components/ContentContainer';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './PasswordChangeEmailSentPage.css';


function PasswordChangeEmailSentPage(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  useDocumentTitle({title: 'Password Change Email'})
  return (
    <ContentContainer
      header={t('New password')}
      title={t('Check your email inbox')}
    >
      <div className="PasswordChangeEmailSentPage-intro">
        <p>
          {t('An email with a link to set a new password has been sent.')}
        </p>
        <p>
          {t('This email has been sent to the email addresses registered on your user account.')}
        </p>
      </div>
      <div className="PasswordChangeEmailSentPage-checkmark">
        <Checkmark />
      </div>
      <div className="PasswordChangeEmailSentPage-buttonContainer">
        <ButtonSecondary 
          buttonText={t('Back')} 
          type={SecondaryButtonType.link} 
          linkTo={`/${org.id}/`} 
          variant={SecondaryButtonVariant.outlinedGhost} />
      </div>
    </ContentContainer>
  );
}

export default PasswordChangeEmailSentPage;
