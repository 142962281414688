import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import './index.css';


// Ensure that React Modal can disable the non-modal parts of the page when
// showing a modal.
ReactModal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
