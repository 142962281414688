import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorImage1 from '../pages/errorimage1.png'

import './ErrorMessage.css'


interface Props {
    message?: string;
    translatedMessage?:React.ReactElement;
    showError: boolean
}

function ErrorMessage({message, translatedMessage, showError}: Props): React.ReactElement {
  const { t } = useTranslation<string>();
  if (showError) {
    return (
      <div aria-live='polite' role='status' className='errorMessage'>
        <img className='errorMessage-icon' src={ErrorImage1} alt='' />
        { message && <p className='errorMessage-text'>{t(message)}</p>}
        { translatedMessage && <p>{translatedMessage}</p> }
      </div>
    )
  } else {
    return <></>
  }
}

export default ErrorMessage