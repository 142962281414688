import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';

import './PasswordHelpModal.css';


interface Props {
  handleClose: React.MouseEventHandler<HTMLButtonElement>,
  show: boolean,
}

/***
 * Create a modal containing details for setting password.
 * @param props
 */
function PasswordHelpModal(props: Props): React.ReactElement {
  const { t } = useTranslation<string>();

  return (
    <Modal
      id="pwmodal"
      isOpen={props.show}
      onRequestClose={props.handleClose}
      title={t('Creating good passwords')}
    >
      <ul className='PasswordHelpModal-details'>
        <li>{t('Create a password that is easy to remember but difficult to guess.')}</li>
        <li>{t('We recommend that you create your password as a sentence.')}</li>
        <li>{t('You are welcome to use spaces and more words.')}</li>
        <li>{t('If you want, you can use numbers, special characters and lowercase and uppercase letters.')}</li>
        <li>{t('New passwords should not be too similar to previous passwords, nor should they contain your name or username.')}</li>
        <li>{t('Change passwords periodically.')}</li>
        <li>{t('Feel free to use a password manager service to take care of passwords.')}</li>
      </ul>
    </Modal>
  );
}

export default PasswordHelpModal;
