export class Account {
  constructor(
    readonly id: string,
    readonly username: string,
    readonly activated: boolean,
    readonly name: string,
    readonly email: string,
    readonly phone: string,
    readonly isShortTermGuest: boolean,
  ) {
  }
}

export class NotAuthenticatedError extends Error {
  name = 'NotAuthenticatedError';
}
export class MissingUserError extends Error {
  name = 'MissingUserError';
}
export class AccountNotReadyToActivateError extends Error {
  name = 'AccountNotReadyToActivateError';
}

export class FeideAccountNotActivatedError extends Error {
  message = 'You got logged into Feide, but your account is not active at this organization.';
}

interface ApiAccount {
  id:        string;
  username:  string;
  activated: boolean;
  name:      string;
  email:     string;
  phone:     string;
  isShortTermGuest: boolean;
}

export async function fetchAccounts(orgBackendId: string): Promise<Array<Account>> {
  const res = await fetch(`/api/accounts/${orgBackendId}/`);
  if (!res.ok) {
    const responseText = (await res.text()).trim()
    if (res.status === 401) {
      throw new NotAuthenticatedError();
    } else if(res.status === 500 && responseText === 'Account not ready') {
      throw new AccountNotReadyToActivateError;
    } else if (res.status === 500 && responseText === 'Feide account not activated') {
      throw new FeideAccountNotActivatedError;
    } else {
      throw new Error('Error fetching accounts');
    }
  }
  const accounts = await res.json();
  if (accounts.length === 0) {
    // Backend has reported that we are indeed authenticated, but we did not receive any account information. That
    // indicates that no user was found using NIN or FeideID at the organization
    throw new MissingUserError();
  }
  return accounts.map((accountData: ApiAccount)=>new Account(
    accountData.id,
    accountData.username,
    accountData.activated,
    accountData.name,
    accountData.email,
    accountData.phone,
    accountData.isShortTermGuest,
  ));
}

export default Account;
