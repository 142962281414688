import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Account from '../api/Account';
import ActivationStageContainer from '../components/ActivationStageContainer';
import Checkmark from '../components/Checkmark';
import { SimplifiedLink, SimplifiedLinkText } from '../components/SimplifiedLink';
import { useDocumentTitle } from '../hooks/UseDocumentTitle';

import './ActivatedStage.css';


function PostActivationLink(): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOrganization();

  let href = org.website;
  if (org.postActivationLink) {
    href = org.postActivationLink;
  }

  return (
    <SimplifiedLink href={href.localize()}>
      {t('To')} <SimplifiedLinkText/>
    </SimplifiedLink>
  );
}

interface Props {
  account: Account;
}

function ActivatedStage({account}: Props): React.ReactElement {
  const { t } = useTranslation<string>();
  const org = useOrganization();
  useDocumentTitle({title: 'Account Activated'});
  let message = '';
  let messageLink = '';
  if (org.postActivationMessage) {
    message = org.postActivationMessage.localize()
  }
  if (org.postActivationMessageLink) {
    messageLink = org.postActivationMessageLink.localize();
  }

  return (
    <ActivationStageContainer
      currentStep={8}
      footer={
        !account.isShortTermGuest &&
        <div className="ActivatedStage-bottom-container">
          <PostActivationLink />
        </div>
      }
      title={t('Activation completed')}
    >
      <div className="ActivatedStage-info">
        <p>{t('The account will be ready for login in two to three minutes.')}</p>
        {
          account.isShortTermGuest ?
            <p>UserID: <b>{account.username}</b></p>
            :
            <p>UserID (Feide): <b>{account.username}</b></p>
        }
        {
          account.isShortTermGuest ?
            <p>{t('We’ve sent an onboarding message to your email address')} <b>{account?.email}</b>.</p>
            :
            <p>{t('We’ve sent an onboarding message to both your private email address and your new email address')} <b>{account?.email}</b>.</p>
        }
        { !account.isShortTermGuest && <p>{message} <a href={messageLink} target='_blank' rel='noopener noreferrer'>{messageLink}</a></p> }
        <p>{t('You can close this window.')}</p>
      </div>
      <Checkmark />
    </ActivationStageContainer>
  )
}

export default ActivatedStage;
