import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganization } from '../OrganizationContext';
import Modal from './Modal';

import './ContactAndHelpModal.css';


interface Props {
  title: string,
  children?: React.ReactNode,
  handleClose: React.MouseEventHandler<HTMLButtonElement>,
  show: boolean,
}

function contactSection(title: string, contactData?: React.ReactElement, contactDescription?: React.ReactElement): React.ReactElement {

  return (
    <div className='ContactAndHelpModal-section'>
      <h3>{title}</h3>
      <div className='ContactAndHelpModal-details'>
        <p className='ContactAndHelpModal-description'>{contactDescription}</p>
        <div className='ContactAndHelpModal-data'>
          {contactData}
        </div>
      </div>
    </div>
  );
}

/**
 * Returns contacts section containing supportphone, supportchat and supportemail if set in organization details
 */
export function Contacts(): React.ReactElement {
  const org = useOrganization();
  const { t } = useTranslation<string>();
  let supportName = 'helpdesk';
  if (org.supportName) {
    supportName = org.supportName;
  }
  const websiteTitle = org.supportLinkStudent ? t('Website for employees') : t('Website');
  const websiteDescription = org.supportLinkStudent ? <Trans>Get more information at the IT-support website for employees.</Trans> : <Trans>Get more information at the IT-support website.</Trans>
  const websiteLinkTitle = org.supportLinkStudent ? t('Go to the helpdesk website for employees in a new tab') : t('Go to the helpdesk website in a new tab') ;

  return (
    <>
      {
        org.supportPhone ?
          contactSection(
            t('Phone'),
            <a title={t('Helpdesk phone number')} href={'tel:' + org.supportPhone}>{org.supportPhone}</a>,
            <Trans>Call IT-support.</Trans>) :
          <></>
      }
      {
        org.supportChat?.localize() ?
          contactSection(
            t('Chat'),
            <a target="_blank" rel="noopener noreferrer" title={t('Go to support chat in new tab')} href={org.supportChat.localize()}>{org.supportChat.localize()}</a>,
            <Trans>Do not provide personal information when contacting {{supportName}} using chat.</Trans>): 
          <></>
      }
      {
        org.supportEmail ?
          contactSection(
            t('E-Mail'),
            <a href={'mailto:' + org.supportEmail}>{org.supportEmail}</a>,
            t('Send E-Mail to helpdesk')) :
          <></>
      }
      {
        org.supportLinkStudent?.localize() ?
          contactSection(
            t('Website for students'),
            <a target="_blank" rel="noopener noreferrer" title={t('Go to the helpdesk website for students in a new tab')} href={org.supportLinkStudent.localize()}>{org.supportLinkStudent.localize()}</a>,
            <Trans>Get more information at the IT-support website for students.</Trans>) : 
          <></>
      }
      {
        org.supportLink?.localize() ?
          contactSection(
            websiteTitle,
            <a target="_blank" rel="noopener noreferrer" title={websiteLinkTitle} href={org.supportLink.localize()}>{org.supportLink.localize()}</a>,
            websiteDescription) : 
          <></>
      }
    </>
  );
}

function GenericContactModal(props: Props): React.ReactElement {
  return (
    <Modal
      id="modal"
      isOpen={props.show}
      onRequestClose={props.handleClose}
      title={props.title}
    >
      {props.children}
      <Contacts />
    </Modal>
  );
}

export default GenericContactModal;
